import { Dispatch } from 'redux';
import store from '~/data/store';
import { getInvoiceServiceRequest } from '~/data/store/reducer/billing/actions/getInvoiceService';

import { iActionGetInvoiceService } from '~/domain/interfaces/redux/billing/getInvoiceService';

import { GetInvoiceService } from '~/domain/usecases/billing/redux';

export class ReduxGetInvoiceService implements GetInvoiceService {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  get = (params: GetInvoiceService.Params): iActionGetInvoiceService =>
    this.send(getInvoiceServiceRequest(params));
}
