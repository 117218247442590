/* eslint-disable react/require-default-props */
import React from 'react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import Modal from '../modal';
import { formattedUrl } from '~/utils/formattedUrl';

interface ownProps {
  closeCallback?: () => void;
}

export const ViewMedia: React.FC<ownProps> = ({ closeCallback }) => {
  const { active, actionOk, actionCancel, url, title, mimeType } = useSelector(
    (store: iStore) => store.message,
  );

  const modalName = MessageOptions.viewMediaModal;
  const isOpen = active === modalName;

  return (
    <Modal open={isOpen} closeCallback={closeCallback} title={`${title}`}>
      {mimeType === 'VIDEO' && url && (
        <iframe
          title={title}
          src={formattedUrl(url)}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          style={{ width: '100%', height: '100%' }}
        />
      )}
      {mimeType === 'IMAGE' && <img src={url} alt={title} />}
    </Modal>
  );
};

export default ViewMedia;
