import React from 'react';
import { Message, Container } from './styles/StyledMessage';
import { translator } from '../i18n';

interface Security {
  scores: number;
}

const MessageSecurity: React.FC<Security> = ({ scores }) => {
  const scoresData: any = {
    0: translator('Fraca'),
    1: translator('Fraca'),
    2: translator('Médio'),
    3: translator( 'Forte'),
    4: translator( 'Forte'),
  };

  return (
    <Container>
      {translator('Senha de')}
      <Message>{scoresData[scores]}</Message>
      {translator('segurança')}
    </Container>
  );
};

export default MessageSecurity;
