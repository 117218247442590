import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const Header = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  height: 10vh;
  padding: 1rem 5rem;
  cursor: pointer;
  background-color: #fff;

  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  color: #32193a;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const StSection = styled.section`
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  display: block;

  /* max-width: 1920px; */

  @media (max-width: 768px) or (orientation: portrait) {
    position: initial;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    overflow: initial;
  }
`;

export const BackgroundImage = styled.img`
  min-height: 100%;
  object-fit: cover;
  width: 100%;
  vertical-align: start;

  border-style: none;
  z-index: -1;
  position: absolute;

  bottom: 0;
`;

const PositionerCss = css`
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;

  width: auto;
  height: auto;

  overflow: hidden;
`;

export const CarouselDiv = styled.div`
  width: auto;

  min-height: 13rem;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 1rem;

  object-fit: cover;

  aspect-ratio: 16/9;

  @media (max-width: 360px) or (max-height: 800px) {
    display: none;
  }

  @media (min-width: 768px) {
    ${PositionerCss}

    top: 15%;
    right: 5%;

    width: 45%;
    /* max-width: 40rem; */
    aspect-ratio: 16/9;
  }
`;

export const MediasDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.6rem;
  padding: 1rem;
  margin-left: 16px;

  @media (min-width: 768px) {
    ${PositionerCss}
    align-items: start;
    justify-content: center;

    width: calc(50% - 16px);

    top: 25%;
    left: 0;
  }
`;

type MediaProps = {
  orientation?: 'vertical' | 'horizontal';
};

export const Media = styled.img<MediaProps>`
  background: #fff;
  border-radius: 5px;
  border: 5px solid #32193a;

  max-width: 60%;
  max-height: 60%;

  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }

  @media (min-width: 768px) {
    width: 40%;
    height: 40%;

    max-width: 60rem;
    max-height: 40rem;
  }
`;

export const ChairsDiv = styled.div`
  ${PositionerCss}
  width: 70rem;
  bottom: 0%;
  left: 45%;

  img {
    width: 50rem;
  }

  @media (min-width: 1920px) {
    width: auto;
    right: 0;
    left: initial;

    img {
      width: 60rem;
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const DeskDiv = styled.div`
  bottom: 0;
  right: 0;
  left: 0;

  /* max-width: 30rem; */
  /* min-width: 20rem; */

  padding: 1rem 1rem 0 1rem;

  @media (max-width: 768px) {
    right: auto;
  }

  @media (min-width: 768px) {
    ${PositionerCss}

    width: 40%;
    max-width: 45rem;

    img {
      width: 100%;
    }
  }

  @media (min-width: 1920px) {
    width: auto;
    max-width: 50rem;

    img {
      width: 100%;
    }
  }
`;

export const NotificationDiv = styled.div`
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 1rem;

  @media (min-width: 768px) {
    ${PositionerCss}
    bottom: initial;
    top: 58%;
    right: 5%;

    width: 45%;
    /* max-width: 40rem; */
    padding: 1rem 0;
    margin: 0;
  }
`;

export const NotificationBalloon = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;

  background: #fff;

  padding: 1rem;
  border-radius: 5px;

  .avatar {
    margin-right: 1rem;
  }

  .title {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1rem;
  }

  .description {
    font-size: 1rem;
    font-weight: 500;
  }

  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);

  @media (max-width: 768px) or (orientation: portrait) {
    &:before {
      content: '';
      position: absolute;
      left: 24.5%;
      bottom: -7px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 7px 7px 7px;
      border-color: transparent transparent #fff transparent;
      z-index: 9998;

      transform: rotate(180deg);
    }

    &:after {
      content: '';
      position: absolute;
      left: 25%;
      bottom: -4px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 4px 4px 4px;
      border-color: transparent transparent #fff transparent;
      z-index: 9999;

      transform: rotate(180deg);
    }
  }
`;

export const FlowerPotDiv = styled.div`
  ${PositionerCss}
  width: auto;
  bottom: 0;
  left: 0;

  svg {
    width: auto;
    height: auto;
    max-width: 10rem;
  }

  @media (min-width: 1920px) {
    width: auto;
    left: -2%;

    svg {
      width: auto;
      height: auto;
      max-width: 10rem;
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
`;
