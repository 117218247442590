import styled, { css } from 'styled-components';

export const Message = styled.div`
  font-weight: bold;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 5px;
  padding-top: 10px;
`;
