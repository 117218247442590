import React from 'react';
import zxcvbn from 'zxcvbn';
import Bar from './Bar';
import MessageSecurity from './Message';
import { Container } from './styles/StyledPasswordValidationBar';

interface wordScore {
  password: string;
}

const PasswordValidationBar: React.FC<wordScore> = ({ password }) => {
  const testResult = zxcvbn(password);
  let num = (testResult.score * 100) / 3;

  if (num > 100) {
    num = 100;
  }

  return (
    <Container>
      <Bar score={testResult.score} num={num} />
      <MessageSecurity scores={testResult.score} />
    </Container>
  );
};

export default PasswordValidationBar;
