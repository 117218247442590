import styled, { css } from 'styled-components';
import { shade } from 'polished';

export const Container = styled.form`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Header = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /*   height: auto; */
`;

export const Body = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
`;

export const Footer = styled.footer`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-size: 12px;
  margin: 20px;
  color: #64696f;
  font-weight: bold;
  gap: 20px;

  .links {
    display: flex;
    gap: 5px;
    align-items: center;
    font-size: 12px;
  }
`;

export const Content = styled.div`
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

export const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  color: #444a51;
`;

export const Span = styled.span`
  width: 60%;
  text-align: center;
  font-size: 14px;
  color: ${props => props.theme.typographyDarkGray};
`;

const SizeVariant = css`
  text-decoration: none;
  color: ${props => props.theme.primary};
  cursor: pointer;
  :hover {
    color: ${shade(0.2, '#1756E8')};
  }
`;

export const SignUp = styled.a`
  ${SizeVariant};
  font-weight: bold;
  font-size: 18px;
`;

export const Forgotpass = styled.a`
  ${SizeVariant};
  font-size: 16px;
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 15px;
  padding-bottom: 15px;
  /* gap: 20px; */
`;

export const ContainerButtons = styled.div`
  width: 412px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  /* margin-top: 30px; */
  border: 1px solid #efefef;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 40px 36px;
`;

export const CancelButton = styled.button`
  color: #1b65b2;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  width: 100%;
`;
