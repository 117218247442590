import React from 'react';

import { Menu } from '~/presentation/components/menu';

import { RegisterAdm as RegisterUserForm } from '~/presentation/components/registerAdm';

import { Container, Body } from './StyledRegisterAdm';
import { History } from '~/main/routes';
import { PageHeader } from '~/presentation/components/header';
import { translator } from '~/presentation/components/i18n';

const RegisterAdmPage: React.FC = (): JSX.Element => {
  return (
    <Container>
      <Menu />
      <PageHeader
        title={translator('Cadastrar novo administrador')}
        confirmChanges
      />
      <Body>
        <RegisterUserForm />
      </Body>
    </Container>
  );
};

export default RegisterAdmPage;
