import React from 'react';
import { Container, Body } from './styles/StyledClinical';
import { ClinicalDoc } from '~/presentation/components/clinicalDoc';
import { Menu } from '~/presentation/components/menu';
import { HeaderSimples } from '~/presentation/components/header';
import { IconHome } from '~/presentation/base/icons';
import { translator } from '~/presentation/components/i18n';

const ClinicalPage: React.FC = () => {
  return (
    <Container>
      <Menu />
      <HeaderSimples
        title={translator('Documentos clínicos')}
        icon={IconHome}
      />

      <Body>
        <div id="clinicalContent">
          <ClinicalDoc />
        </div>
      </Body>
    </Container>
  );
};

export default ClinicalPage;
