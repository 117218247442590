import React, { ComponentType } from 'react';
import { useSelector } from 'react-redux';

import _ from 'lodash';
import { iInvoiceResults, iStore } from '~/domain/interfaces/models';

import { ownProps, InvoiceSelectOptions } from '../interface';

/**
 * Connect component.
 * @param Component componente to connect.
 */
export function ConnectComponent<P>(
  Component: ComponentType<P & ownProps>,
): React.FC<P> {
  const Render: React.FC<P> = ({ ...rest }) => {
    const { invoiceItems } = useSelector((state: iStore) => state.billing);

    Component.defaultProps = {
      invoiceMonths: MapInvoiceMonths(invoiceItems),
      ...(rest as P),
    };

    return <Component {...(rest as P & ownProps)} />;
  };

  return Render;
}

/**
 * Map list invoiceMonths to component.
 * @param invoiceItems list of invoiceMonths.
 */
export const MapInvoiceMonths = (
  invoiceItems: iInvoiceResults,
): InvoiceSelectOptions[] => {
  const arr: InvoiceSelectOptions[] = [];

  if (invoiceItems?.items) {
    invoiceItems?.items.forEach(item => {
      const formattedDate = new Date(`${item.invoice.begin}T00:00`);
      const month = formattedDate.toLocaleString('pt-BR', {
        month: 'long',
      });
      const formattedMonth = month.replace(
        month[0],
        month.charAt(0).toUpperCase(),
      );

      const label = `${formattedMonth} ${formattedDate.getFullYear()}`;

      arr.push({
        label,
        value: item.invoice.id,
      });
    });
  }

  return arr;
};
