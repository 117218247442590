import React, { useState } from 'react';

import { StepMagic, Step } from '~/presentation/components/steps';
import { Button } from '~/presentation/components/UI';

import { translator } from '~/presentation/components/i18n';
import {
  Content,
  Header,
  Body,
  Footer,
  ButtonStep,
} from './styles/styledInterConsulta';

import GeneralData from './generalData';
import Professional from './Professionals';
import DateAndTime from './DateAndTime';
import { CreateAppointment } from '~/domain/usecases/appointment/redux/CreateAppointment';
import { AddAppointmentData } from '~/domain/usecases/appointment/redux/AddApointmentData';
import { iAppointment } from '~/domain/interfaces/models/Appointment';
import { ConnectComponent } from './mapper/MapperInterConsulta';
import { iInterConsulta } from './interface';

export interface ownProps {
  ActionAppointment: CreateAppointment;
  Appointment: AddAppointmentData;
  AppointmentData: iAppointment;
}

const InterConsulta: React.FC<ownProps> = props => {
  // const [state, setState] = useState<iInterConsulta>({
  //   generalData: {
  //     consultant: 0,
  //     speciality: 0,
  //   },
  //   dateAndTime: {
  //     dateToAppointment: '',
  //     hourFinish: 0,
  //     hourStart: 0,
  //   },
  //   professional: [],
  // });
  const handleSubmit = () => {
    props.ActionAppointment.create({
      consultant: Number(props.AppointmentData.create.data.consultant),
      professional: Number(props.AppointmentData.create.data.professional),
      specialty: Number(props.AppointmentData.create.data.specialty),
      date: new Date().toISOString(),
      duration: Number(props.AppointmentData.create.data.duration),
      org: 1,
      orgUnit: 1,
      organizer: 2,
      type: 'FIRST',
    });
  };

  const steps: any = {
    1: <GeneralData />,
    2: <DateAndTime />,
    3: <Professional />,
  };

  const Next = translator('Próximo');
  const Cancel = translator('Cancelar');
  const Finish = translator('Concluir');
  const Back = translator('Voltar');

  return (
    <Content>
      <Header>
        <StepMagic current={props.AppointmentData.create.step}>
          <Step title={translator('Dados gerais')} />
          <Step title={translator('Data e Hora')} />
          <Step title={translator('Profissionais')} />
        </StepMagic>
      </Header>
      <Body>{steps[props.AppointmentData.create.step]}</Body>
      <Footer>
        <Button size="small" rounded variant="secundary" color="red">
          {Cancel}
        </Button>
        <ButtonStep>
          {props.AppointmentData.create.step > 1 ? (
            <Button
              size="small"
              rounded
              onClick={() =>
                props.Appointment.add({
                  step: props.AppointmentData.create.step - 1,
                  // eslint-disable-next-line prettier/prettier
                })
              }
            >
              {Back}
            </Button>
          ) : null}
          <Button
            size="small"
            rounded
            onClick={
              props.AppointmentData.create.step === 3
                ? () => handleSubmit()
                : () =>
                    props.Appointment.add({
                      step: props.AppointmentData.create.step + 1,
                    })
            }
          >
            {props.AppointmentData.create.step === 3 ? Finish : Next}
          </Button>
        </ButtonStep>
      </Footer>
    </Content>
  );
};

export default ConnectComponent(InterConsulta);
