import { iAuth } from '~/domain/interfaces/models';
import { AuthTypes } from '~/domain/interfaces/redux/auth/types';
import { AuthActions } from './actions';

export const initialState: iAuth = {
  loading: false,
  access: {},
  refresh: {},
  // Atualizar mainRole após atualizar papel
  info: {},
  // Atualizar ACL após atualizar papel
  acl: {
    totalItems: 0,
    resources: [],
  },
  orgs: [],
  // Atualizar role selecionada após atualizar papel
  selectUser: {
    orgUnitId: undefined,
    orgId: undefined,
    roleId: 0,
    role: 'CON',
  },
};

const reducer = (state = initialState, action: AuthActions): iAuth => {
  switch (action.type) {
    case AuthTypes.LOGIN:
      return { ...state, loading: true };
    case AuthTypes.LOGIN_SUCCESS:
      // console.log('action login: ', action.payload);
      return { ...state, ...action.payload, loading: false };
    case AuthTypes.LOGIN_FAILED:
      return { ...state, loading: false };
    case AuthTypes.REFRESH:
      return { ...state, loading: true };
    case AuthTypes.REFRESH_SUCCESS:
      // console.log('action refresh: ', action.payload);
      return { ...state, ...action.payload, loading: false };
    case AuthTypes.REFRESH_FAILED:
      return { ...state, loading: false };
    case AuthTypes.UPDATE_USER_INFO: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      action.payload.user.mainRole = state.info.user!.mainRole;
      // action.payload.user.mainRole = 'ADM';
      return { ...state, info: action.payload };
    }
    case AuthTypes.GET_ACL:
      return { ...state, loading: true };
    case AuthTypes.GET_ACL_SUCCESS:
      return { ...state, acl: { ...action.payload }, loading: false };
    case AuthTypes.GET_ACL_FAILED:
      return { ...state, loading: false };
    case AuthTypes.SELECT_USER:
      return {
        ...state,
        selectUser: { ...state.selectUser, ...action.payload },
      };
    case AuthTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
