import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import storeDev from '~/data/store';
import { ReduxLogout } from '~/data/store/reducer/auth/usecases/ReduxLogout';
import { iStore } from '~/domain/interfaces/models';
import { makeReduxDeleteUser } from '~/main/factories/usecases/users/DeleteUserFactory';
import { makeReduxGetAllUsers } from '~/main/factories/usecases/users/GetAllUsersFactory';
import { History } from '~/main/routes';
import { Trash } from '~/presentation/base/icons';
import { translator } from '../i18n';
import { Icon } from '../icon';
import { Button } from '../UI/buttons';
import { Body, Container, Header } from './style/StyledDeleteAccount';

interface iParams {
  id: string;
}

export default function DeleteAccount() {
  const info = useSelector((state: iStore) => state.auth.info);
  const { id } = useLocation<iParams>().state;

  const handleLogout = useCallback(() => {
    const logoutApp = new ReduxLogout();
    logoutApp.logout();
    History.push('/');
  }, []);

  const deleteAccount = () => {
    if (id !== undefined) {
      try {
        const response = makeReduxDeleteUser().delete({ id: Number(id) });
        // const allusers = makeReduxGetAllUsers().getAll({});
      } catch (e) {
        console.log(e);
      }
    } else {
      try {
        const response = makeReduxDeleteUser().delete({
          id: Number(info!.user!.id!),
        });
        // const allUsers = makeReduxGetAllUsers().getAll({});
      } catch (e) {
        console.log(e);
      }
    }
  };

  return (
    <Container>
      <Header>
        <Icon src={Trash} color="#656A6E" height="24px" />
        {translator('Excluir conta')}
      </Header>
      <Body>
        <div id="notice">{translator('Aviso importante')}</div>
        <div id="text">{translator('Ao excluir um usuário, as informações pertinentes a eles como dados de atendimentos, documentos clínicos e de apoio como também as conversas em chat, serão mantidas de acordo com as politicas de privacidade e termos de uso da plataforma V4H atendimento. ')}</div>
        <Button
          actions={['DELETE_OWN']}
          id="action"
          type="button"
          color="red"
          variant="secundary"
          rounded
          onClick={() => deleteAccount()}
        >
          {translator('Excluir usuário')}
        </Button>
      </Body>
    </Container>
  );
}
