import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: ${props => props.theme.background};
  overflow: scroll;
`;

export const Body = styled.div`
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;

  #list-container {
    height: calc(100vh - 220px);
    overflow: hidden;
  }
`;

export const HeaderList = styled.div`
  display: flex;
  align-items: center;
  max-width: 100%;
  margin: 25px 0;
  justify-content: space-between;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Attendiment = styled.div`
  width: 25px;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 10px;
`;
