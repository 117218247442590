import { takeLatest } from 'redux-saga/effects';
import {
  onGetInvoiceByOrg,
  onGetInvoiceByOrgFailed,
  onGetInvoiceByOrgSuccess,
} from './GetInvoiceByOrg';

import {
  onGetInvoiceService,
  onGetInvoiceServiceSuccess,
  onGetInvoiceServiceFailed,
} from './GetInvoiceService';

import { BillingTypes } from '~/domain/interfaces/redux/billing/types';

const tasks = [
  takeLatest(BillingTypes.GET_INVOICE_BY_ORG, onGetInvoiceByOrg),
  takeLatest(BillingTypes.GET_INVOICE_BY_ORG_SUCCESS, onGetInvoiceByOrgSuccess),
  takeLatest(BillingTypes.GET_INVOICE_BY_ORG_FAILED, onGetInvoiceByOrgFailed),

  takeLatest(BillingTypes.GET_INVOICE_SERVICE, onGetInvoiceService),
  takeLatest(
    BillingTypes.GET_INVOICE_SERVICE_SUCCESS,
    onGetInvoiceServiceSuccess,
  ),
  takeLatest(
    BillingTypes.GET_INVOICE_SERVICE_FAILED,
    onGetInvoiceServiceFailed,
  ),
];

export default tasks;
