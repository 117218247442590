export enum BillingTypes {
  GET_INVOICE_BY_ORG = '@billing/GET_INVOICE_BY_ORG',
  GET_INVOICE_BY_ORG_SUCCESS = '@billing/GET_INVOICE_BY_ORG_SUCCESS',
  GET_INVOICE_BY_ORG_FAILED = '@billing/GET_INVOICE_BY_ORG_FAILED',
  GET_INVOICE_SERVICE = '@billing/GET_INVOICE_SERVICE',
  GET_INVOICE_SERVICE_SUCCESS = '@billing/GET_INVOICE_SERVICE_SUCCESS',
  GET_INVOICE_SERVICE_FAILED = '@billing/GET_INVOICE_SERVICE_FAILED',
  GET_INVOICE_SERVICE_BILLING = '@billing/GET_INVOICE_SERVICE_BILLING',
  GET_INVOICE_SERVICE_BILLING_SUCCESS = '@billing/GET_INVOICE_SERVICE_BILLING_SUCCESS',
  GET_INVOICE_SERVICE_BILLING_FAILED = '@billing/GET_INVOICE_SERVICE_BILLING_FAILED',
}
