/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';

import { makeReduxSetData } from '~/main/factories/usecases/message/SetData';

import {
  Container,
  Body,
  Title,
  ItemRole,
  Itens,
} from './styles/StyledOrgItem';

interface ownProps {
  title: string;
  roles: { name: string; role: 'ORG' | 'PRO' | 'CON' | 'ADM'; id: number }[];
  orgId: number;
  orgUnitId: number;
}

export default function OrgItem(props: ownProps): JSX.Element {
  const title =
    props.title.charAt(0).toLocaleUpperCase() + props.title.slice(1);

  const sUser = useSelector((store: iStore) => store.auth.selectUser);
  const data = useSelector((store: iStore) => store.message.data);

  const orgId = data.orgId === undefined ? sUser.orgId : data.orgId;

  return (
    <Container>
      <Body>
        <Title>{` ${title}`}</Title>
        <Itens>
          {props.roles.map(item => {
            return (
              <ItemRole
                id={`btn_${props.orgId}_${item.id}`}
                selected={props.orgId === orgId}
                color="transparent"
                height="24px"
              >
                {item.name}
              </ItemRole>
            );
          })}
        </Itens>
      </Body>
    </Container>
  );
}
