import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import InputMask from 'react-input-mask';
import { setDate } from 'date-fns/esm';
import { Container, Title } from './style/StyledGeneralData';

import Input from '../UI/input';
import { translator } from '../i18n';
import { Select } from '~/presentation/components/UI';

import { schemaPerfil } from '~/validation/validators/user/UpdateUserValidator';
import { iStore } from '~/domain/interfaces/models';
import { makeReduxSetShowModal } from '~/main/factories/usecases/showModal/Set';

interface ownProps {
  form: any;
  state: any;
  changeState: (state: any) => void;
  disableAutoFocus?: boolean;
}

const GeneralData: React.FC<ownProps> = ({
  form,
  state,
  changeState,
  disableAutoFocus = false,
}) => {
  const { errors, register, setValue } = form;
  const { loading } = useSelector((store: iStore) => store.users);
  const { profile } = useSelector((store: iStore) => store.showModal);
  const [generalData, setData] = useState(state);
  const generalDatai18n = translator('Dados gerais');
  const fullNamei18n = translator('Nome completo');
  const genderi18n = translator('Gênero');
  const cpfi18n = translator('Cadastro de Pessoa Física');
  const registerRgi18n = translator('Número da identidade');
  const motherNamei18n = translator('Nome da mãe');
  const dateOfBirthdayi18n = translator('DD/MM/AAAA');
  const labelDateOfBirth = translator('Data de Nascimento');

  // console.log('general data', state);

  const { documents } = generalData;

  useEffect(() => {
    makeReduxSetShowModal().set({ profile: false });
  }, []);

  const handleDocumentChange = (e: any) => {
    const { name, value } = e.target;
    const documentsUpdated = documents.map((doc: any) =>
      doc.name.toLowerCase() === name
        ? { ...doc, value: value.replace(/[^\d]+/g, '').trim() }
        : doc,
    );
    changeState({ ...generalData, documents: documentsUpdated });
    setValue(name, value.replace(/[^\d]+/g, '').trim());
  };

  useEffect(() => {
    setData(state);
  }, [state]);

  return (
    <Container>
      <Title>{generalDatai18n}</Title>
      <Input
        key={generalData.fullName}
        name="fullName"
        label={fullNamei18n}
        height="40px"
        onChange={e => {
          if (!profile) makeReduxSetShowModal().set({ profile: true });
          setValue('fullName', e.target.value.trim());
        }}
        defaultValue={generalData.fullName}
        register={() => register('fullName')}
        error={Boolean(errors.fullName)}
        message={
          errors?.fullName?.message ? translator(errors?.fullName?.message) : ''
        }
        pattern="[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$"
        required
        autoFocus={!disableAutoFocus}
        disabled={loading}
      />
      <Select
        key={generalData.gender}
        label={genderi18n}
        height="40px"
        id="registerGender"
        name="gender"
        register={() => register('gender')}
        defaultValue={generalData.gender}
        placeholder={translator('Selecione seu Gênero')}
        onChange={e => {
          if (!profile) makeReduxSetShowModal().set({ profile: true });
          setValue('gender', e.target.value);
        }}
        disabled={loading}
      >
        <option id="registerOptionMan" value="MALE">
          {translator('Masculino')}
        </option>
        <option id="registerOptionWoman" value="FEMALE">
          {translator('Feminino')}
        </option>
        <option id="registerOptionOthers" value="OTHER">
          {translator('Outros')}
        </option>
      </Select>
      {/* <InputMask
        key={generalData.cpf}
        name="cpf"
        mask="999.999.999-99"
        defaultValue={generalData.cpf}
        onChange={e => {
          if (!profile) makeReduxSetShowModal().set({ profile: true });

          const cpf = e.target.value.replace(/[^\d]+/g, '').trim();
          setValue('cpf', cpf);
        }}
        ref={() => register('cpf')}
        disabled={loading}
      >
        <Input
          id="registerCpf"
          height="40px"
          name="cpf"
          placeholder={cpfi18n}
          label="CPF"
          error={Boolean(errors.cpf)}
          message={errors?.cpf?.message ? translator(errors?.cpf?.message) : ''}
          required
          disabled={loading}
        />
      </InputMask> */}

      {documents?.map((document: any) => {
        if (document.name === 'CPF') {
          return (
            <InputMask
              key={document.id}
              name={document.name.toLowerCase()}
              mask="999-999-999.99"
              defaultValue={document.value}
              onChange={e => {
                if (!profile) makeReduxSetShowModal().set({ profile: true });
                handleDocumentChange(e);
              }}
              ref={() => register('cpf')}
              disabled={loading}
            >
              <Input
                id="registerCpf"
                height="40px"
                name="cpf"
                placeholder={cpfi18n}
                label="CPF"
                error={Boolean(errors.cpf)}
                message={
                  errors?.cpf?.message ? translator(errors?.cpf?.message) : ''
                }
                required
                disabled={loading}
              />
            </InputMask>
          );
        }
        return <></>;
      })}

      <Input
        key={generalData.motherName}
        name="motherName"
        label={motherNamei18n}
        height="40px"
        defaultValue={generalData.motherName}
        onChange={e => {
          if (!profile) makeReduxSetShowModal().set({ profile: true });
          setValue('motherName', e.target.value.trim());
        }}
        register={() => register('motherName')}
        disabled={loading}
      />
      <InputMask
        key={generalData.birthdate}
        mask="99/99/9999"
        height="40px"
        name="birthdate"
        defaultValue={generalData.birthdate}
        ref={() => register('birthdate')}
        onChange={e => {
          if (!profile) makeReduxSetShowModal().set({ profile: true });

          const birthDate = e.target.value.trim();
          setValue('birthdate', birthDate);
        }}
        disabled={loading}
      >
        <Input
          id="registerBirthDate"
          placeholder={dateOfBirthdayi18n}
          label={labelDateOfBirth}
          error={Boolean(errors.birthdate)}
          message={errors?.birthdate?.message}
          disabled={loading}
        />
      </InputMask>
    </Container>
  );
};

export default GeneralData;
