import { createIntl, createIntlCache } from 'react-intl';
import { AppointmentTypes } from '~/domain/interfaces/redux/appointment/types';
import { active as activeMessage } from '~/data/store/reducer/message/actions/active';
import { MessageOptions } from '~/domain/interfaces/redux/message/types';
import { History } from '~/main/routes';
import { makeRemoteGetAppointmentById } from '~/main/factories/usecases/appointment/GetAppointmentbyIdFactory ';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

/**
 * Middleware to handler the user message.
 * @param action action received by redux.
 * @param next function to pass the action.
 */
export const AppointmentNotifications = async (
  action: any,
  next: any,
  closeNotification: any,
) => {
  const { type } = action;

  switch (type) {
    case AppointmentTypes.CREATE_SUCCESS:
      try {
        const appointment =
          await makeRemoteGetAppointmentById().getAppointmentById({
            id: action?.payload?.id,
          });

        return next(
          activeMessage({
            active: MessageOptions.appointmentCreate,
            content: intl.formatMessage({
              id: 'Atendimento agendado com sucesso!',
            }),
            actionOk: () => {
              next(closeNotification);
              History.push('/appointment');
            },
            appointmentLinks: [
              appointment.links.professional,
              appointment.links.consultant,
            ],
          }),
        );
      } catch (error) {
        return next(action);
      }

    case AppointmentTypes.DELETE:
      return next(
        activeMessage({
          active: MessageOptions.appointmentConfirmCancel,
          actionOk: () => {
            next(closeNotification);
            next(action);
          },
          actionCancel: () => {
            next(closeNotification);
          },
        }),
      );
    case AppointmentTypes.DELETE_SUCCESS:
      return next(
        activeMessage({
          active: MessageOptions.success,
          title: intl.formatMessage({
            id: 'Atendimento cancelado com sucesso!',
          }),
          actionOk: () => next(closeNotification),
        }),
      );
    case AppointmentTypes.UPDATE_SUCCESS:
      return next(
        activeMessage({
          active: MessageOptions.success,
          content: intl.formatMessage({ id: 'Alterações salvas com sucesso!' }),
          actionOk: () => {
            next(closeNotification);
            next(action);
          },
        }),
      );
    case AppointmentTypes.CANCEL:
      return next(
        activeMessage({
          active: MessageOptions.appointmentConfirmCancel,
          actionOk: () => {
            next(closeNotification);
            next(action);
          },
          actionCancel: () => {
            next(closeNotification);
          },
        }),
      );
    case AppointmentTypes.CANCEL_SUCCESS:
      return next(
        activeMessage({
          active: MessageOptions.success,
          content: intl.formatMessage({
            id: 'Atendimento cancelado com sucesso!',
          }),
          actionOk: () => {
            next(closeNotification);
            next(action);
          },
        }),
      );
    case AppointmentTypes.STOP:
      return next(
        activeMessage({
          active: MessageOptions.appointmentStop,
          actionOk: () => {
            next(closeNotification);
            next(action);
          },
          actionCancel: () => {
            next(closeNotification);
          },
        }),
      );
    default:
      break;
  }
  return next(action);
};
