import React from 'react';

import { GridInfoBilling, TableBorder } from './styles/StyledBillingAcordeon';

import { PropsAccordeon } from './interface';

const InfoBilling: React.FC<PropsAccordeon> = ({ items }) => {
  return (
    <>
      {items?.length &&
        items.map(item => (
          <TableBorder key={item?.billing.id}>
            <GridInfoBilling>
              <div style={{ paddingLeft: '30px' }}>{item?.billing.title}</div>
              <div>-</div>
              <div>{item?.billing.amount?.toFixed(3)}</div>
              <div>-</div>
              <div>-</div>
              <div>
                {new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }).format(item?.billing.price)}
              </div>
            </GridInfoBilling>
          </TableBorder>
        ))}
    </>
  );
};

export default InfoBilling;
