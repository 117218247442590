import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Container,
  Item,
  ItemsContainer,
  V4HLogo,
} from './styles/sidebarStyles';

import {
  ClinicalDocIcon,
  ClinicalDocSelectedIcon,
  ESusIcon,
  NotesIcon,
  NotesSelectedIcon,
  SupportDocIcon,
  SupportDocSelectedIcon,
} from '~/presentation/base/icons';

import { Notes } from './notes';
import { ClinicalDocs } from './clinicalDocs';
import { SupportDocs } from './supportDocs';
import { makeRemoteGetAppointmentById } from '~/main/factories/usecases/appointment/GetAppointmentbyIdFactory ';
import { GetAppointmentById } from '~/domain/usecases/appointment/remote';
import { iStore } from '~/domain/interfaces/models';

enum SidebarOptions {
  NONE = 0,
  NOTES = 1,
  CLINICALDOCS = 2,
  SUPPORTDOCS = 3,
}

export interface ParamsState {
  appointmentId: string;
}

const Sidebar: React.FC = () => {
  const [selectedOption, setSelectedOption] = useState<number>(
    SidebarOptions.NONE,
  );
  const [appointment, setAppointment] = useState<GetAppointmentById.Model>(
    {} as GetAppointmentById.Model,
  );
  const { role } = useSelector((store: iStore) => store.auth.selectUser);
  const { appointmentId } = useLocation<ParamsState>().state;

  const openPopup = useCallback(() => {
    window.open(
      'http://200.98.131.52:8080/esus/#/pec',
      'popup',
      'width=600,height=600',
    );
  }, []);

  const renderContent = useMemo(() => {
    switch (selectedOption) {
      case SidebarOptions.NOTES:
        return (
          <Notes
            handleClose={() => setSelectedOption(SidebarOptions.NONE)}
            appointment={appointment}
          />
        );
      case SidebarOptions.CLINICALDOCS:
        return (
          <ClinicalDocs
            handleClose={() => setSelectedOption(SidebarOptions.NONE)}
          />
        );
      case SidebarOptions.SUPPORTDOCS:
        return (
          <SupportDocs
            handleClose={() => setSelectedOption(SidebarOptions.NONE)}
          />
        );
      default:
        return <div />;
    }
  }, [selectedOption, appointment]);

  useEffect(() => {
    if (appointmentId)
      makeRemoteGetAppointmentById()
        .getAppointmentById({ id: Number(appointmentId) })
        .then(res => {
          setAppointment(res);
        })
        .catch(err => {
          console.log('err: ', err);
        });
  }, [appointmentId]);

  return (
    <>
      <Container>
        <ItemsContainer>
          {role === 'PRO' && (
            <Item
              selected={selectedOption === SidebarOptions.NOTES}
              onClick={() => setSelectedOption(SidebarOptions.NOTES)}
            >
              {selectedOption === SidebarOptions.NOTES ? (
                <NotesSelectedIcon />
              ) : (
                <NotesIcon />
              )}
            </Item>
          )}
          <Item
            selected={selectedOption === SidebarOptions.CLINICALDOCS}
            onClick={() => setSelectedOption(SidebarOptions.CLINICALDOCS)}
          >
            {selectedOption === SidebarOptions.CLINICALDOCS ? (
              <ClinicalDocSelectedIcon />
            ) : (
              <ClinicalDocIcon />
            )}
          </Item>
          <Item
            selected={selectedOption === SidebarOptions.SUPPORTDOCS}
            onClick={() => setSelectedOption(SidebarOptions.SUPPORTDOCS)}
          >
            {selectedOption === SidebarOptions.SUPPORTDOCS ? (
              <SupportDocSelectedIcon />
            ) : (
              <SupportDocIcon />
            )}
          </Item>
          <Item onClick={openPopup}>
            <ESusIcon />
          </Item>
        </ItemsContainer>
        <V4HLogo />
      </Container>
      {renderContent}
    </>
  );
};

export default Sidebar;
