import React from 'react';
import { useLocation } from 'react-router-dom';

import { translator } from '~/presentation/components/i18n';
import { Button } from '~/presentation/components/UI/buttons';
import { IconCancel, ArrowRight } from '~/presentation/base/icons';
import { Info } from './interface';
import { makeReduxCancelAppointment } from '~/main/factories/usecases/appointment/CancelAppointmentFactory';

import {
  Content,
  Header,
  Body,
  Footer,
  Buttons,
  ContentCancel,
  InfoCancel,
  Message,
  Date,
} from './styles/StyledActions';
import { History } from '~/main/routes';
import { makeReduxSetupAppointment } from '~/main/factories/usecases/appointment/SetupAppointmentFactory';

interface ActionsProps {
  status: keyof typeof Info | undefined;
  cancelDate?: string;
}

interface iParamsTypes {
  appointmentId: string;
}

const Actions: React.FC<ActionsProps> = ({ status, cancelDate }) => {
  const stateParams = useLocation<iParamsTypes>().state;
  const onSubmit = () => {
    makeReduxSetupAppointment().setup({
      appointmentId: Number(stateParams.appointmentId),
    });
    makeReduxCancelAppointment().cancel({
      appointmentId: Number(stateParams.appointmentId),
    });
  };

  const agendado = (
    <Button
      id="cancelAppointment"
      onClick={onSubmit}
      variant="secundary"
      rounded
      icon={IconCancel}
      color="#ff0000"
      size="large"
      height="44px"
    >
      {translator('Cancelar atendimento')}
    </Button>
  );
  const cancelado = (
    <ContentCancel>
      <IconCancel id="btn_delete" />
      <InfoCancel>
        <Message>
          {translator('Atendimento cancelado')}
        </Message>
        <Date>{cancelDate ?? 'em 23/12/2020 às 11:11'}</Date>
      </InfoCancel>
    </ContentCancel>
  );
  const finalizado = <h1>finalizado</h1>;
  const disponivel = (
    <Buttons>
      <Button rounded size="large" height="44px" icon={ArrowRight}>
        {translator('Iniciar atendimento')}
      </Button>
      <Button
        id="cancelAppointment"
        variant="secundary"
        rounded
        icon={IconCancel}
        color="#ff0000"
        size="large"
        height="44px"
      >
        {translator('Cancelar atendimento')}
      </Button>
    </Buttons>
  );

  const VariantStatus = {
    SCHEDULED: agendado,
    CANCELED: cancelado,
    ENDED: finalizado,
    AVAILABLE: disponivel,
    CONFIRMED: agendado,
    INPROGRESS: 'In progress',
  };

  return (
    <Content>
      <Header>
        {translator('Ações do atendimento')}
      </Header>
      <Body>{status ? VariantStatus[status] : null}</Body>
      {/* <Footer>{translator('Para reagendar ou alterar dados do atendimento, você precisa editar o atendimento nos campos ao lado.')}</Footer> */}
    </Content>
  );
};

export default Actions;
