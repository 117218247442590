import React from 'react';

import { IconCalendar } from '~/presentation/base/icons';
import { PageHeader } from '~/presentation/components/header';
import { translator } from '~/presentation/components/i18n';
import { Menu } from '~/presentation/components/menu';
import { RegiterAppointment } from '~/presentation/components/registerAppointment';
import { Container } from './styles';

const NewAppointment: React.FC = () => {
  return (
    <Container>
      <Menu />
      <PageHeader
        title={translator('Agendar novo atendimento')}
        icon={IconCalendar}
        confirmChanges
      />
      <RegiterAppointment />
    </Container>
  );
};

export default NewAppointment;
