import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { GetInvoiceByOrg as UsecaseRemoteGetInvoiceByOrg } from '~/domain/usecases/billing/remote';
import { GetInvoiceByOrg as UseCaseReduxGetInvoiceByOrg } from '~/domain/usecases/billing/redux';

import { RemoteGetInvoiceByOrg } from '~/data/repository/billing';
import { ReduxGetInvoiceByOrg } from '~/data/store/reducer/billing/usecases';

/**
 * send request via API.
 */
export const makeRemoteGetInvoiceByOrg = (): UsecaseRemoteGetInvoiceByOrg =>
  new RemoteGetInvoiceByOrg(makeApiUrl('/billing'), makeHttpClient());

/**
 * send request via REDUX.
 */
export const makeReduxGetInvoiceByOrg = (): UseCaseReduxGetInvoiceByOrg =>
  new ReduxGetInvoiceByOrg();
