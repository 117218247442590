import styled from 'styled-components';
import media from '~/utils/mediaqueries';

export const Container = styled.div`
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  padding-top: 10%;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  /* padding: 0.5rem; */
  width: 960px;
  height: 80vh;
  max-height: 80%;
  background: #fff;
  border-radius: 0.5rem;
  outline: 0;

  ${media.lessThan('md')} {
    width: 90vw;
  }

  @media (max-height: 768px) {
    height: 80vh;
  }
`;

export const HeaderModal = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.3rem;
  background: #fff;
  position: relative;
  border-radius: 0.5rem;

  #icon_close_modal {
    position: absolute;
    align-items: center;
    justify-content: center;
    top: calc(50% - 0.7rem);
    right: 1.4rem;
    width: 1.4rem;
    height: 1.4rem;
    cursor: pointer;
  }
`;

export const BodyModal = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding: 10px; */
  background: #fff;
  position: relative;
  overflow: auto;
  background-color: #e7e8ef;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 0.5rem;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #df2c26; // TODO: Colocar tema e trocar as cores
    border-radius: 0.5rem;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #df2c26;
  }
`;

export const TitleHeaderModal = styled.h3`
  font-size: 2rem;
  font-weight: 400;
  color: #7a7a7a;
`;
