import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const Body = styled.div`
  padding-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 80px;
  #clinicalContent {
    max-width: 1500px;
    width: 100%;
  }
`;
