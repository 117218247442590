import {
  number,
  object,
  string,
  boolean,
  array,
  enum as a,
  union,
  literal,
} from 'zod';

export const schemaGeneralData = object({
  consultant: number().nonnegative({
    message: 'Consultante inválido ou não encontrado.',
  }),
  professional: number().nonnegative({
    message: 'Profissional inválido ou não encontrado.',
  }),
  specialty: number().nonnegative({
    message: 'Especialidade inválida ou não encontrada',
  }),
  type: a(['FIRST', 'RETURN', 'NEW', 'none']).refine(
    value => value === 'FIRST' || value === 'RETURN',
    { message: 'Opção inválida' },
  ),
});

export const schemaDateHours = object({
  date: string().nonempty({ message: 'Data inválida' }),
  hourStart: string().nonempty({ message: 'Hora de ínicio incorreta' }),
  hourEnd: string().nonempty({ message: 'Hora final incorreta' }),
});

export const schemaEditConsult = object({
  consultant: number().nonnegative({
    message: 'Consultante inválido ou não encontrado.',
  }),
  professional: number().nonnegative({
    message: 'Profissional inválido ou não encontrado.',
  }),
  specialty: number().nonnegative({
    message: 'Especialidade inválida ou não encontrada',
  }),
  date: string().nonempty({ message: 'Data inválida' }),
  hourStart: string().nonempty({ message: 'Hora de ínicio incorreta' }),
  hourEnd: string().nonempty({ message: 'Hora final incorreta' }),
  service: string().optional(),
  type: a(['FIRST', 'RETURN', 'NEW', 'none']).refine(
    value => value === 'FIRST' || value === 'RETURN' || value === 'none',
    { message: 'Opção inválida' },
  ),
  serviceArea: number().nonnegative({
    message: 'Selecione a área de atendimento',
  }),
});

export const schemaGuest = object({
  email: union([string().email(), literal('')]),
  name: string()
    .max(128)
    .nonempty({ message: 'Insira seu nome completo.' })
    .optional(),
});

export const schema = object({
  appointmentId: number().nonnegative(),
  professional: number().nonnegative().optional(),
  specialty: number().nonnegative().optional(),
  consultant: number().nonnegative().optional(),
  organizer: number().nonnegative().optional(),
  healthPlan: number().nonnegative().optional(),
  obs: string().optional(),
  status: a(['CANCELED', 'ENDED']).optional(),
  priority: a([
    'REGULAR',
    'ELDERLY',
    'PREGNANT',
    'NURSINGMOTHER',
    'INFANT',
    'CHILDREN_UP_TO_5',
    'EMERGENCY',
  ]).optional(),
  type: a(['FIRST', 'RETURN', 'NEW']).optional(),
  allowInvite: boolean().optional(),
  date: string().optional(),
  duration: number().optional(),
  note: string().nonempty().optional(),
  companions: array(
    object({
      id: number().nonnegative().optional(),
      name: string().optional(),
      phone: string(),
      email: string(),
      role: a([
        'GRANDPARENT',
        'PARENT',
        'SPOUSE',
        'SIBLING',
        'SON',
        'RELATIVE',
        'FRIEND',
        'OTHER',
      ]),
    }),
  ).optional(),
  professionals: array(
    object({
      id: number().nonnegative().optional(),
      name: string().optional(),
      phone: string(),
      email: string(),
      specialty: string(),
    }),
  ).optional(),
}).refine(data => {
  if (
    (data.professional || data.specialty) &&
    (!data.professional || !data.specialty)
  )
    return false;
  return true;
});
