/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ServicesIcon } from '~/presentation/base/icons';
import { Select } from '~/presentation/components/UI';
import {
  ContentRight,
  HeaderStatusServices,
  ContentStatusServices,
  Cards,
  Status,
  StatusServices,
} from './styles/StyledServicesAdmin';
import { ConnectComponent } from './mapper/ServicesAdminMapper';
import { ownProps } from './interface';
import { ServicesCardOnDemand } from '~/presentation/components/servicesCard';
import { iStore } from '~/domain/interfaces/models';
import { makeReduxGetDashboardProducts } from '~/main/factories/usecases/dashboard/GetDashboardProductsFactory';
import { translator } from '../i18n';

const ServicesAdmin: React.FC<ownProps> = ({ dashboardMonths }) => {
  const [dashboardSelected, setDashboardSelected] = useState<string>('DEFAULT');
  const { products } = useSelector((store: iStore) => store.dashboard);
  const { selectUser } = useSelector((store: iStore) => store.auth);

  useEffect(() => {
    const formattedDate = dashboardSelected.split('/');

    makeReduxGetDashboardProducts().get({
      filter: {
        org: selectUser.orgId,
        unit: 1,
        month:
          dashboardSelected !== 'DEFAULT'
            ? [Number(formattedDate[0])]
            : undefined,
        year:
          dashboardSelected !== 'DEFAULT'
            ? [Number(formattedDate[1])]
            : undefined,
      },
    });
  }, [selectUser.orgId, dashboardSelected]);

  return (
    <ContentRight>
      <HeaderStatusServices>
        <ServicesIcon />
        <text id="service">{translator('Serviços')}</text>
        <text id="flat">{translator('(Pacote On Demand)')}</text>
      </HeaderStatusServices>
      <ContentStatusServices>
        <Select
          label={translator('Mês de competência')}
          width="250px"
          onChange={e => setDashboardSelected(e.target.value)}
          value={dashboardSelected}
        >
          <option value="DEFAULT">{translator('Selecione um mês')}</option>
          {dashboardMonths.map(item => (
            <option value={`${item.value.month}/${item.value.year}`}>
              {item.label}
            </option>
          ))}
        </Select>
        <StatusServices>
          <p>
            <strong>Status: </strong>
            <Status status={products?.active ? 'paid out' : 'overdue'}>
              {products?.active ? 'Ativo' : 'Inativo'}
            </Status>
          </p>
          <p>
            <strong>{translator('Vigência')}: </strong>
            <text>
              {`${
                products?.start
                  ? new Date(products?.start).toLocaleDateString()
                  : '01/02/2021'
              } - ${
                products?.end
                  ? new Date(products?.end).toLocaleDateString()
                  : '01/03/2021'
              }`}
            </text>
          </p>
        </StatusServices>
      </ContentStatusServices>
      <Cards>
        <ServicesCardOnDemand
          type="BACKUP"
          percentValue={products?.storage?.diff}
          value={products?.storage?.used}
          active={products?.storage?.active}
          width="242px"
          height="165px"
        />
        <ServicesCardOnDemand
          type="APPOINTMENT"
          percentValue={products?.appointments?.diff}
          value={products?.appointments?.used}
          active={products?.appointments?.active}
          width="242px"
          height="165px"
        />
        <ServicesCardOnDemand
          type="PRESCRIPTION"
          percentValue={products?.prescriptions?.diff}
          value={products?.prescriptions?.used}
          active={products?.prescriptions?.active}
          width="242px"
          height="165px"
        />
        <ServicesCardOnDemand
          type="PROTECT"
          percentValue={products?.preservation?.diff}
          value={products?.preservation?.used}
          active={products?.preservation?.active}
          width="242px"
          height="165px"
        />
        <ServicesCardOnDemand
          type="BLOCKCHAIN"
          percentValue={products?.blockchain?.diff}
          value={products?.blockchain?.used}
          active={products?.blockchain?.active}
          width="242px"
          height="165px"
        />
        <ServicesCardOnDemand
          type="FACIAL_RECOGNITION"
          percentValue={products?.biometry?.diff}
          value={products?.biometry?.used}
          active={products?.biometry?.active}
          width="242px"
          height="165px"
        />
      </Cards>
    </ContentRight>
  );
};

export default ConnectComponent(ServicesAdmin);
