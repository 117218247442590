/* eslint-disable react/require-default-props */
import React from 'react';
import { useSelector } from 'react-redux';
import { Modal as ModalMui } from '@material-ui/core';
import { IconBell, IconClose } from '~/presentation/base/icons';
import { iStore } from '~/domain/interfaces/models';

import {
  BodyModal,
  Container,
  Content,
  HeaderModal,
  TitleHeaderModal,
} from './viewMedia/styles';

interface ModalProps {
  title: string;
  children: React.ReactNode;
  open: boolean;
  closeCallback?: () => void;
}

export const Modal: React.FC<ModalProps> = ({
  title,
  children,
  open,
  closeCallback,
}) => {
  const { actionCancel } = useSelector((store: iStore) => store.message);

  const handleClose = () => {
    if (closeCallback) closeCallback();
    if (actionCancel) actionCancel();
  };

  return (
    <div>
      <ModalMui open={open} onClose={handleClose}>
        <Container>
          <Content>
            <HeaderModal>
              <TitleHeaderModal>{title}</TitleHeaderModal>
              <IconClose id="icon_close_modal" onClick={handleClose} />
            </HeaderModal>
            <BodyModal>{children}</BodyModal>
          </Content>
        </Container>
      </ModalMui>
    </div>
  );
};

export default Modal;
