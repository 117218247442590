import React, { useEffect, useState } from 'react';

import { HeaderSimples } from '~/presentation/components/header';
import { SearchListAppointment } from '~/presentation/components/search-list';
import { Button } from '~/presentation/components/UI';
import { Menu } from '~/presentation/components/menu';

import {
  iListRegisteredAdmin,
  ListRegisteredAdmin,
} from '../../../../components/ListAdmin';

import {
  Container,
  Body,
  HeaderList,
  Wrapper,
  Attendiment,
} from './StyledSchedule';
import { History } from '~/main/routes';

import { ConnectAdmin } from '~/presentation/components/ListAdmin/mapper/MapperListAdmin';

import { TablePagination } from '~/presentation/components/tablePagination';
import { iStore } from '~/domain/interfaces/models';
import { IconPlus } from '~/presentation/base/icons';
import { translator } from '~/presentation/components/i18n';
import {
  FilterAdministrator,
  FilterAppointment,
} from '~/presentation/components/filter';
import { makeReduxDataPagination } from '~/main/factories/usecases/dataPagination/SetDataFactory';
import SearchListAdministrator from '~/presentation/components/search-list/SearchListAdministrator';

export interface pageProps {
  data: iListRegisteredAdmin[];
}
const ListAdmin: React.FC<pageProps> = ({ data }): JSX.Element => {
  const url = window.location.pathname;
  const [state, setState] = useState(data);

  useEffect(() => {
    if (data.length === 0) {
      makeReduxDataPagination().set([]);
    }

    setState(data);
  }, [data]);

  return (
    <Container>
      <Menu />
      <HeaderSimples
        title={translator('Administradores cadastrados')}
        value={state.length}
        valueDescription={translator('Cadastrados')}
      />

      <Body>
        <HeaderList>
          {state.length > 0 && state !== undefined ? (
            <TablePagination data={state} itemPerPage={10} />
          ) : (
            <div />
          )}
          <Wrapper>
            <div>
              <SearchListAdministrator>
                <FilterAdministrator />
              </SearchListAdministrator>
            </div>
            <Button
              id="button_create"
              onClick={() =>
                History.getHistory().push('/administrator/register', {
                  from: url.replaceAll('/', ''),
                })
              }
              size="large"
              resourcers={['test']}
              rounded
            >
              <Attendiment>
                <IconPlus />
              </Attendiment>
              <text>{translator('Novo administrador')}</text>
            </Button>
          </Wrapper>
        </HeaderList>
        <ListRegisteredAdmin />
      </Body>
    </Container>
  );
};

export default ConnectAdmin(ListAdmin);
