import styled from 'styled-components';

interface StatusProps {
  status: 'ACTIVE' | 'INACTIVE' | 'SUSPENDED' | 'REMOVED';
}

export const Container = styled.div``;

export const Status = styled.div<StatusProps>`
  color ${props => {
    switch (props.status) {
      case 'ACTIVE':
        return '#004ba5';
      case 'INACTIVE':
        return '#7a7a7a';
      case 'SUSPENDED':
        return '#e49963';
      default:
        return '';
    }
  }}
`;
