import styled from 'styled-components';

interface styleBar {
  scores: number;
  width: string;
}

export const Container = styled.div`
  width: 100%;
  background: ${props => props.theme.typographyLightGray};
  border-radius: 5px;
  height: 7px;
  margin-top: 20px;
`;

export const Barra = styled.div<styleBar>`
  width: ${props => props.width};
  height: 7px;
  border-radius: 5px;
  background: ${props => {
    switch (props.scores) {
      case 0:
        return '#828282';
      case 1:
        return '#EC5C52';
      case 2:
        return '#004BA5';
      case 3:
        return '#00b500';
      case 4:
        return '#00b500';
      default:
        return 'none';
    }
  }};
`;
