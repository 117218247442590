import styled from 'styled-components';

interface SidebarProps {
  sidebar?: boolean;
}

export const ContainerNotes = styled.div<SidebarProps>`
  width: 100%;
  height: 100%;
  padding: ${props => (props.sidebar ? 0 : '10px')};
`;

export const ContentNotes = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .eye-icon {
    margin-right: 3px;
  }
  .text-info {
    font-size: 14px;
    color: #7a7a7a;
  }
  p {
    display: flex;
    align-items: center;
  }
`;

export const ContentNotesEdition = styled.div`
  width: 100%;
  display: flex;
  height: 20px;
  align-items: center;
  justify-content: initial;
  .clock-icon {
    margin-right: 3px;
  }
  .text-info {
    font-size: 14px;
    color: #7a7a7a;
  }
`;

export const ContainerModal = styled.div<SidebarProps>`
  margin-top: 5px;
  background-color: #fbfbfb;
  border: ${props => (props.sidebar ? '1px solid #7A7A7A' : '1px solid #efefef')};
  padding: ${props => (props.sidebar ? 0 : '10px')};
  border-radius: 5px;
  overflow-y: scroll;
  width: 100%;
  height: 600px;
  max-height: 600px;
  font-size: 11px;
  resize: none;
`;
