/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-one-expression-per-line */
// eslint-disable-next-line react/jsx-one-expression-per-line
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  iInvoiceResultsItem,
  iInvoiceServiceResults,
  iStore,
} from '~/domain/interfaces/models';
import { ConnectComponent } from './mapper/invoiceMapper';
import { makeReduxGetInvoiceByOrg } from '~/main/factories/usecases/billing/GetInvoiceByOrgFactory';
import { makeReduxGetInvoiceService } from '~/main/factories/usecases/billing/GetInvoiceService';
import { InvoiceIcon } from '~/presentation/base/icons';
import { Select } from '~/presentation/components/UI';
import { BillingAcordeon } from '../BillingAcordeon';
import { ownProps } from './interface';
import {
  ContainerItensPayment,
  ContainerPayment,
  ContentHeaders,
  ContentStatusPayment,
  DownloadInvoice,
  HeaderLeftPayment,
  HeaderRightPayment,
  Information,
  RightStatusPayment,
  Status,
  StatusPayment,
  TitleItensPayment,
  TotalPayment,
} from './styles/StyledInvoiceAndPayments';
import { translator } from '../i18n';

const InvoiceAndPayments: React.FC<ownProps> = ({ invoiceMonths }) => {
  const [checked, setChecked] = useState(false);
  const [invoiceMonthSelected, setInvoiceMonthSelected] = useState(-1);
  const [billingData, setBillingData] = useState<iInvoiceResultsItem>(
    {} as iInvoiceResultsItem,
  );
  const [invoiceServiceItems, setInvoiceServiceItems] =
    useState<iInvoiceServiceResults>({} as iInvoiceServiceResults);

  const { orgId } = useSelector((store: iStore) => store.auth.selectUser);
  const { invoiceItems, serviceItems } = useSelector(
    (store: iStore) => store.billing,
  );

  useEffect(() => {
    if (orgId) makeReduxGetInvoiceByOrg().get({ org: orgId });
  }, [orgId]);

  useEffect(() => {
    if (invoiceMonthSelected !== -1 && orgId) {
      makeReduxGetInvoiceService().get({
        org: orgId,
        invoice: invoiceMonthSelected,
      });
      setChecked(false);
    }
  }, [invoiceMonthSelected]);

  // TODO: Atualizar billingData de acordo com select de data a ser filtrado
  useEffect(() => {
    if (invoiceItems?.items && invoiceItems?.items?.length) {
      const found = invoiceItems?.items.find(
        item => item.invoice.id === invoiceMonthSelected,
      );
      setBillingData(found ?? invoiceItems.items[0]);
    }
  }, [invoiceItems, invoiceMonthSelected]);

  useEffect(() => {
    if (
      invoiceMonthSelected !== -1 &&
      serviceItems?.items &&
      serviceItems?.items?.length
    ) {
      setInvoiceServiceItems(serviceItems);
    } else {
      setInvoiceServiceItems({} as iInvoiceServiceResults);
    }
  }, [serviceItems, invoiceMonthSelected]);

  // TODO: Atualizar com itens
  function handleCollapseDetails() {
    setChecked(false);
  }

  // TODO: Adicionar mapper com tratamento de datas e lógica de atualização do Redux
  return (
    <ContainerPayment>
      <ContentHeaders>
        <HeaderRightPayment id="receiptsHeader">
          <InvoiceIcon />
          <text>{translator('Faturas e pagamentos')}</text>
        </HeaderRightPayment>
        <HeaderLeftPayment>
          {/* TODO: Aguardando implementação do back-end para reativar funcionalidades */}
          {/* <CsvIcon />
          <Impress /> */}
        </HeaderLeftPayment>
      </ContentHeaders>
      <ContentStatusPayment>
        <StatusPayment>
          <RightStatusPayment>
            <Select
              label={translator('Mês')}
              width="250px"
              onChange={e => setInvoiceMonthSelected(Number(e.target.value))}
              value={invoiceMonthSelected}
            >
              <option value={-1}>{translator('Selecione um mês')}</option>
              {invoiceMonths.map(item => (
                <option value={item.value}>{item.label}</option>
              ))}
            </Select>
            <StatusPayment>
              <p>
                {translator('Status de pagamento')}:
                <Status
                  status={billingData?.invoice?.paid ? 'paid out' : 'opened'}
                >
                  {billingData?.invoice?.paid
                    ? `${translator('Pago')}`
                    : `${translator('Em aberto')}`}
                </Status>
              </p>
              <p>
                <Status>
                  {`${translator('Vencimento')}: ${
                    new Date(
                      `${billingData?.invoice?.end}T00:00`,
                    ).toLocaleDateString() ?? `10/02/2021`
                  }`}
                </Status>
              </p>
            </StatusPayment>
          </RightStatusPayment>
        </StatusPayment>
        <DownloadInvoice>
          <text>
            {`${translator('Vigência')}: ${
              new Date(
                `${billingData?.invoice?.begin}T00:00`,
              ).toLocaleDateString() ?? `05/01/2021`
            } | ${
              new Date(
                `${billingData?.invoice?.end}T00:00`,
              ).toLocaleDateString() ?? `05/02/2021`
            }`}
          </text>
          {/* TODO: Aguardando implementação do back-end para reativar funcionalidades */}
          {/* {billingData?.invoice?.paid ? (
            <Button rounded size="medium" variant="secundary">
              <IconButton>
                <InvoiceDownload />
                Baixar comprovante
              </IconButton>
            </Button>
          ) : (
            <Button rounded size="medium" variant="primary">
              <IconButton>
                <InvoiceDownload />
                Baixar fatura
              </IconButton>
            </Button>
          )} */}
        </DownloadInvoice>
      </ContentStatusPayment>
      <TotalPayment>
        <text>{translator('Total estimado')}</text>
        <Status status={billingData?.invoice?.paid ? 'paid out' : 'opened'}>
          {new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: billingData?.invoice?.currency ?? 'BRL',
          }).format(billingData?.invoice?.value)}
        </Status>
      </TotalPayment>
      <Information>
        <p>
          {translator(
            'O valor total faturado será exibido depois que a fatura completa for fechada.',
          )}
        </p>
      </Information>
      <TitleItensPayment colorStatus={checked}>
        <text>{translator('Itens da fatura')}</text>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div
          id="link"
          onClick={() => {
            handleCollapseDetails();
          }}
        >
          {translator('Ocultar todos os detalhes')}
        </div>
      </TitleItensPayment>
      <ContainerItensPayment>
        <BillingAcordeon
          invoiceServiceItems={invoiceServiceItems.items}
          show={checked}
          setShow={setChecked}
          invoiceId={billingData?.invoice?.id}
        />
      </ContainerItensPayment>
    </ContainerPayment>
  );
};

export default ConnectComponent(InvoiceAndPayments);
