/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState, useEffect } from 'react';

import { createIntl, createIntlCache } from 'react-intl';
import { useSelector } from 'react-redux';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';

import { StepMagic, Step } from '~/presentation/components/steps';

import { PersonalData, Address, Contacts } from '../register';
// import Address from './Address';
import ProfessionalData from './ProfessionalData';
import { translator } from '~/presentation/components/i18n';
import { Container, Header, Body } from './style/StyledRegisterProfessional';

// import { CreateUser } from '~/domain/usecases/users/remote';
import { iRegisterProfessional } from './interface';
import { CreateProfessional as ReduxCreateProfessional } from '~/domain/usecases/professionalList/redux';
import { schema } from '~/validation/validators/user/CreateUserValidator';

import { V4hSpin } from '~/presentation/components/spin';
import { iStore } from '~/domain/interfaces/models';
import { AlertMessage } from '../messages/AlertMessage';
import { makeReduxCreateProfessional } from '~/main/factories/usecases/professional/CreateProfessionalFactory';
import Sectors from './Sectors';
import { makeRemoteGetAllUsers } from '~/main/factories/usecases/users/GetAllUsersFactory';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

const RegisterProfessional: React.FC = props => {
  const selectUser = useSelector((store: iStore) => store.auth.selectUser);

  const org = {
    org: selectUser?.orgId || 0,
    orgUnit: selectUser?.orgUnitId || 0,
  };

  const [crement, setCrement] = useState<number>(1);
  const [submit, setSubmit] = useState<boolean>(false);
  const [isFound, setIsFound] = useState<boolean>(false);
  const [user, setUser] = useState<iRegisterProfessional>({
    fullName: '',
    email: '',
    login: '',
    cpf: '',
    documents: [],
    phone: '',
    phone2: '',
    phone3: '',
    birthdate: '',
    avatar: '',
    motherName: '',
    address: {
      city: '',
      complement: '',
      neighborhood: '',
      number: '',
      street: '',
      uf: '',
      zipcode: '',
    },
    gender: 'MALE',
    mainRole: 'PRO',
  });

  useEffect(() => {
    if (submit) createUser();
  }, [submit]);

  const FINAL_STEP = selectUser.role === 'ADM' ? 5 : 4;

  const loading = useSelector((store: iStore) => store.users.loading);

  function handleBar(number: number) {
    if (crement + number > FINAL_STEP) {
      setSubmit(true);
      return;
    }

    setSubmit(false);
    setCrement(crement + number);
  }

  const createUser = async () => {
    const spe = user.professional?.specialties?.filter(item => {
      const splitDate = item.docProfIssued.split('/');
      item.docProfIssued = `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`;

      delete item.cardId;
      return item;
    });

    const splitName = user.fullName!.split(' ');
    const firstName = splitName?.splice(0, 1).join('');
    const lastName = splitName?.join(' ');
    const birthDateBack = user!.birthdate!.split('/');

    const now = new Date();

    if (user?.sectors?.length && user?.id) {
      const userRegistered = await makeRemoteGetAllUsers().getAll({
        filter: { cpf: user?.cpf },
      });

      try {
        user.sectors.forEach((sector, index) => {
          if (
            userRegistered.results.length &&
            !userRegistered.results[0].professionals.find(
              item => item.orgUnit.id === sector.id,
            )
          ) {
            makeReduxCreateProfessional().create({
              user: user?.id,
              org: org.org,
              orgUnit: sector.id,
              profession: user.professional?.profession,
              specialties: spe!,
            });
          }
        });
      } catch (e) {
        AlertMessage({
          message: intl.formatMessage({
            id: 'Erro ao criar o profissional. Tente novamente.',
          }),
          type: 'danger',
        });
        console.error(e);
      }
    } else {
      const dataToSend: ReduxCreateProfessional.Params = {
        // login: user.fullName!.split(' ').join(''),
        user: isFound ? user.id : undefined,
        org: org.org,
        orgUnit: org.orgUnit,
        profession: user.professional?.profession,
        profile: isFound
          ? undefined
          : {
              firstName,
              lastName,
              email: user.email!,
              phone: user.phone!,
              phone2: user.phone2,
              phone3: user.phone3,
              avatar: user.avatar,
              motherName: user.motherName,
              gender: user.gender!,
              birthdate: `${birthDateBack[2]}-${birthDateBack[1]}-${birthDateBack[0]}`,
            },
        documents: isFound
          ? undefined
          : [
              {
                documentId: 1,
                value: String(user.cpf),
                expires: new Date(
                  now.getFullYear() + 10,
                  now.getMonth(),
                  now.getDate(),
                ),
                issued: now,
                issuer: `${firstName} ${lastName}`,
              },
            ],
        address: isFound
          ? undefined
          : {
              zipcode: user.address!.zipcode!,
              uf: user.address!.uf!,
              city: user.address!.city!,
              country: user.address!.country!,
              neighborhood: user.address!.neighborhood!,
              street: user.address!.street!,
              number: user.address!.number!,
              complement: user.address!.complement!,
            },
        specialties: spe!,
      };

      try {
        const validate = schema.parse(dataToSend);
        console.log('validate: ', validate);
        makeReduxCreateProfessional().create(dataToSend);
      } catch (e) {
        console.error(e);
        AlertMessage({
          message: intl.formatMessage({
            id: 'Erro ao criar o profissional. Tente novamente.',
          }),
          type: 'danger',
        });
      }
    }
  };

  function next(data: iRegisterProfessional) {
    setUser({ ...user, ...data });
    handleBar(1);
  }

  function back(data: iRegisterProfessional) {
    setUser({ ...user, ...data });
    handleBar(-1);
  }

  return (
    <Container>
      <Header>
        {selectUser.role === 'ADM' ? (
          <StepMagic current={crement}>
            <Step title={translator('Dados Pessoais')} />
            <Step title={translator('Contatos')} />
            <Step title={translator('Endereço')} />
            <Step title={translator('Setores')} />
            <Step title={translator('Dados Profissionais')} />
          </StepMagic>
        ) : (
          <StepMagic current={crement}>
            <Step title={translator('Dados Pessoais')} />
            <Step title={translator('Contatos')} />
            <Step title={translator('Endereço')} />
            <Step title={translator('Dados Profissionais')} />
          </StepMagic>
        )}
      </Header>

      <Body>
        {crement === 1 && (
          <PersonalData
            state={user}
            next={next}
            cancel="/appointment"
            isFound={isFound}
            setIsFound={setIsFound}
            roleToRegister="PRO"
          />
        )}
        {crement === 2 && (
          <Contacts state={user} next={next} back={back} isFound={isFound} />
        )}
        {crement === 3 && (
          <Address state={user} next={next} back={back} isFound={isFound} />
        )}
        {crement === 4 &&
          (selectUser.role === 'ADM' ? (
            <Sectors state={user} next={next} back={back} />
          ) : (
            <ProfessionalData
              state={user}
              next={next}
              back={back}
              cancel="/appointment"
            />
          ))}
        {crement === 5 && selectUser.role === 'ADM' && (
          <ProfessionalData
            state={user}
            next={next}
            back={back}
            cancel="/appointment"
          />
        )}
        {loading && <V4hSpin fullScreen />}
      </Body>
    </Container>
  );
};

export default RegisterProfessional;
