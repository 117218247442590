import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  background: #e5e5e5;
  overflow: auto;
  /* min-height: 100vh; */
  /* padding-bottom: 20px; */
`;

export const ContentComponents = styled.div`
  padding: 10px;
`;

export const Nav = styled.div`
  border-radius: 8px;
  background: #f4f7fe;
`;

export const NavContent = styled.div`
  grid-area: navi;
  height: 100%;
  border-radius: 8px;
  background: white;
  display: flex;
  flex-direction: column;
`;
