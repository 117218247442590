import { Dispatch } from 'redux';
import store from '~/data/store';
import { getAllRequest } from '~/data/store/reducer/profession/actions/getAll';

import { iActionGetAll } from '~/domain/interfaces/redux/profession/getAll';

import { GetAllProfession } from '~/domain/usecases/profession/redux';

export class ReduxGetAllProfession implements GetAllProfession {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  getAll = (params: GetAllProfession.Params): iActionGetAll =>
    this.send(getAllRequest(params));
}
