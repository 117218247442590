import styled from 'styled-components';

import {
  IconBack,
  IconCloseButtonModal,
  V4hSpinIconComponent,
} from '~/presentation/base/icons';

export const Container = styled.div`
  width: 51px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: ${props => props.theme.hover};
`;

interface ItemProps {
  selected?: boolean;
  expanded?: boolean;
  padding?: boolean;
  overflow?: boolean;
}

export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Item = styled.div<ItemProps>`
  display: flex;
  width: 100%;
  height: 60px;
  padding: 16px;
  align-items: center;
  justify-content: center;
  background: ${props => (props.selected ? props.theme.header : 'transparent')};
  cursor: ${props => !props.selected && 'pointer'};

  :hover {
    background: ${props => !props.selected && props.theme.statusNone};

    svg {
      transition: ${props => !props.selected && 'all 0.3s ease 0s'};
      transform: ${props => !props.selected && 'scale(1.1)'};
    }
  }
`;

export const V4HLogo = styled(V4hSpinIconComponent)`
  width: 25px;
  height: 27px;
  margin-bottom: 14px;
`;

export const Content = styled.div<ItemProps>`
  display: flex;
  width: ${props => (props.expanded ? '70%' : '35%')};
  height: 100%;
  flex-direction: column;
  justify-content: center;
  background: ${props => props.theme.statusProgress};

  overflow: ${props => props.overflow && 'auto'};

  ::-webkit-scrollbar {
    width: 2px;
    height: 2px;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }
`;

export const ContentHeader = styled.div<ItemProps>`
  display: flex;
  position: relative;
  width: 100%;
  height: 61px;
  align-items: center;
  flex-direction: row;
  border-bottom: ${props => `1px solid ${props.theme.statusNone}`};
`;

export const HeaderGoBackIcon = styled(IconBack)`
  position: absolute;
  width: 20px;
  height: 20px;
  left: 20px;
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
`;

export const HeaderTitle = styled.text`
  width: 100%;
  align-self: center;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.header};
`;

export const HeaderCloseIcon = styled(IconCloseButtonModal)`
  position: absolute;
  width: 28px;
  height: 28px;
  right: 32px;
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
`;

export const ContentBody = styled.div<ItemProps>`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding: ${props => props.padding && '30px 38px'};
  overflow: auto;
`;
