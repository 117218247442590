import React, { useEffect, useState } from 'react';

import { makeReduxDataPagination } from '~/main/factories/usecases/dataPagination/SetDataFactory';
import { IconPlus, RegisteredPatients } from '~/presentation/base/icons';
import { FilterPatient } from '~/presentation/components/filter';

import { HeaderSimples } from '~/presentation/components/header';
import { translator } from '~/presentation/components/i18n';
import { ConnectParticipant } from '~/presentation/components/ListProfessional/mapper/MapperListParticipant';
import { Menu } from '~/presentation/components/menu';
// import {MenuDefault} from '~/presentation/components/menu';
import { SearchListConsultant } from '~/presentation/components/search-list';
import { TablePagination } from '~/presentation/components/tablePagination';
import { Button } from '~/presentation/components/UI';
import { History } from '~/main/routes';

import {
  iListRegisteredParticipant,
  ListRegisteredParticipant,
} from '../../../../components/ListProfessional';

import {
  Container,
  Body,
  HeaderList,
  Wrapper,
  Attendiment,
} from './StyledSchedules';

export interface pageProps {
  data: iListRegisteredParticipant[];
}
const RegisteredParticipant: React.FC<pageProps> = ({ data }): JSX.Element => {
  const [state, setState] = useState(data);
  const url = window.location.pathname;
  useEffect(() => {
    if (data.length === 0) {
      makeReduxDataPagination().set([]);
    }
    setState(data);
  }, [data]);

  // function pushTo(path: string) {
  //   History.push(path);
  // }

  return (
    <Container>
      <Menu />
      <HeaderSimples
        title={translator('Pacientes cadastrados')}
        value={state.length}
        valueDescription={translator('Cadastrados')}
        icon={RegisteredPatients}
      />

      <Body>
        <HeaderList>
          {state.length > 0 && state !== undefined ? (
            <TablePagination data={state} itemPerPage={10} />
          ) : (
            <div />
          )}
          <Wrapper>
            <div>
              <SearchListConsultant>
                <FilterPatient />
              </SearchListConsultant>
            </div>
            <Button
              id="button_create"
              onClick={() =>
                History.getHistory().push('/participant/register', {
                  from: url.replaceAll('/', ''),
                })
              }
              size="large"
              rounded
            >
              <Attendiment>
                <IconPlus />
              </Attendiment>
              <text>{translator('Novo paciente')}</text>
            </Button>
          </Wrapper>
        </HeaderList>
        <ListRegisteredParticipant />
      </Body>
    </Container>
  );
};

export default ConnectParticipant(RegisteredParticipant);
