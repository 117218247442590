import { object, string, number } from 'zod';

export const schema = object({
  login: string().email({ message: 'Email inválido.' }),
  email: string().email({ message: 'Email inválido.' }).optional(),
  password: string().min(6, { message: 'Senha inválida' }),
  org: number().optional(),
}).refine(body => body.email || body.login, {
  message: 'You must enter either a valid email or a login username',
});
