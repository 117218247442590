import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { makeReduxSetShowModal } from '~/main/factories/usecases/showModal/Set';
import { translator } from '~/presentation/components/i18n';
import { Select } from '~/presentation/components/UI';
import { schemaProfessionalData } from '~/validation/validators/user/CreateUserValidator';
import { iSpecialty } from '../professionalData/interface';
import { iListProfession, iRegisterProfessional, Specialty } from './interface';
import { ConnectComponent } from './mapper/map';
import SpecialtyCard, { Cards } from './SpecialtyFunction';
import {
  Body,
  Container,
  Message,
  Profession,
} from './style/StyledProfessionalData';

export interface externalProps {
  state?: iRegisterProfessional;
  specialtyProfile: any[];
  professionID?: number;
  handlePreviousData?: (value: Array<number>) => void;
  handleDeleteData?: (value: Array<number>) => void;
  handleSave?: (value: iSpecialty[]) => void;
  handleDisabled?: (value: boolean) => void;
  save?: boolean;
}

export interface ownProps {
  profession: iListProfession[];
  filterSpecialty: (id: number | undefined) => Specialty[];
}

const ListProfessionalData: React.FC<ownProps & externalProps> = ({
  state,
  profession,
  filterSpecialty,
  specialtyProfile,
  professionID,
  handlePreviousData,
  handleDeleteData,
  handleSave,
  handleDisabled,
  save,
}) => {
  const { specialty: specialtyRedux } = useSelector(
    (store: iStore) => store.showModal,
  );
  const [specialty, setSpecialty] = useState<Cards[]>(
    state?.professional?.specialties || specialtyProfile,
  );

  const [showLength, setShowLength] = useState(0);
  const [disable, setDisable] = useState(true);
  const [professionid, setProfessionId] = useState<number>(professionID || -1);
  const [id, setId] = useState<number>(() => {
    if (specialty.length === 0) return 0;
    return specialty[specialty.length - 1].id!;
  });

  const { errors, handleSubmit, register, setValue, getValues } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    resolver: zodResolver(schemaProfessionalData),
    defaultValues: {
      ...state?.professional,
      profession: professionid,
    },
  });

  const [selectedProfession, setSelectedProfession] = useState(
    getValues().profession || professionid,
  ); //  ** seleciona a profissao

  const [professionalId, setProfessionalId] = useState<number>(
    getValues().profession || professionid,
  );

  const handleSaveChildren = (value: iSpecialty[]) => {
    if (handleSave) handleSave(value);
  };

  const handleClear = () => {
    setSpecialty([]);
  };

  const handler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setValue('profession', Number(event.target.value));
    setProfessionalId(Number(event.target.value));
    setSelectedProfession(Number(event.target.value));
  };

  useEffect(() => {
    if (handleDisabled) handleDisabled(disable);
  }, [disable, handleDisabled]);

  useEffect(() => {
    makeReduxSetShowModal().set({ specialty: false });
  }, []);

  useEffect(() => {
    setProfessionalId(getValues().profession);
    console.log(filterSpecialty(professionalId));
  }, [professionalId]);

  useEffect(() => {
    setSelectedProfession(getValues().profession || -1);
    setProfessionalId(getValues().profession);
  }, [profession]);

  // Quando algum dos specialties do pai atualizarem, atualizar componente filho
  useEffect(() => {
    if (state?.professional?.specialties || specialtyProfile)
      setSpecialty(state?.professional?.specialties || specialtyProfile);
  }, [specialtyProfile]);

  return (
    <Container>
      <Profession>
        <Select
          name="profession"
          width="70%"
          label={translator('Profissão')}
          placeholder="Placeholder"
          defaultValue={state?.professional?.profession}
          disabled={professionid !== -1}
          value={selectedProfession}
          onChange={e => {
            if (!specialtyRedux)
              makeReduxSetShowModal().set({ specialty: true });
            handleClear();
            handler(e);
          }}
          register={() => register('profession')}
          error={Boolean(errors.profession)}
          message={errors?.profession?.message || ''}
          autoFocus
        >
          <option value="select">
            {translator('Selecione sua profissão')}
          </option>
          {profession.map(item => (
            <option value={item.id}>{item.name}</option>
          ))}
        </Select>
      </Profession>
      {/* <Message>
        *Adicione uma ou mais especialidades para concluir o cadastro
      </Message> */}
      <Body>
        <SpecialtyCard
          setDisable={setDisable}
          specialty={filterSpecialty(professionalId)}
          addSpecialty={setSpecialty}
          state={{ specialty }}
          professionID={professionalId}
          id={id}
          setId={setId}
          setLength={setShowLength}
          handleDeleteData={value => {
            if (handleDeleteData) handleDeleteData(value);
          }}
          handlePreviousData={value => {
            if (handlePreviousData) handlePreviousData(value);
          }}
          handleSave={value => handleSaveChildren(value)}
          save={save}
        />
      </Body>
    </Container>
  );
};

export default ConnectComponent(ListProfessionalData);
