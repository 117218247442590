import styled, { css } from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;

  width: 100%;
  height: 100%;

  background: #fbfbfb;
`;

export const Left = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 90px;

  .text {
    width: 90%;
    padding-top: 30px;
    font-weight: 400;
    font-size: 28px;
    line-height: 42px;
    color: #7a7a7a;
  }
`;

export const WiseServices = styled.span`
  font-size: 24px;
  padding-top: 30px;
`;

export const Offerings = styled.div`
  width: 100%;
  max-width: 650px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 30px;
`;

export const Offer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 10px 0px 20px 0px;

  span {
    width: 100%;
    max-width: 370px;
    color: #7a7a7a;
    font-size: 16px;
    margin-left: 30px;
    text-align: justify;
    text-overflow: ellipsis;
  }
`;

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
`;

export const FormContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 30%;
  height: 100%;

  @media (max-width: 1580px) {
    width: 50%;
  }

  @media (max-width: 1300px) {
    width: 70%;
    min-width: 430px;
  }

  @media (max-width: 700px) {
    width: 100%;
    min-width: 300px;

    #logoV4H {
      width: 100%;
    }
  }
`;

export const Title = styled.p`
  font-weight: 500;
  font-size: 36px;
  line-height: 42px;
  color: #444a51;

  margin: 26px 0 26px 0;
`;

export const Form = styled.form`
  display: flex;
  width: 100%;
  min-width: 480px;
  max-width: 480px;
  height: fit-content;
  flex-direction: column;
  padding: 36px;
  background: white;
  border: 1px solid #efefef;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  gap: 16px;
  padding-bottom: 48px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  gap: 8px;
`;

export const Header = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /*   height: auto; */
`;

export const Body = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  /* max-width: 1080px; */
`;

const SizeVariant = css`
  text-decoration: none;
  color: ${props => props.theme.primary};
  cursor: pointer;
  :hover {
    color: ${shade(0.2, '#1756E8')};
  }
`;

export const SignUp = styled.a`
  ${SizeVariant};
  font-weight: bold;
  font-size: 18px;
`;

export const Image = styled.img`
  margin-right: 2px;
`;

export const ImageContainer = styled.div`
  display: flex;
`;
