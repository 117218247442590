/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import React, { useState, useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { createIntl, createIntlCache } from 'react-intl';

import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';

import { iStore } from '~/domain/interfaces/models';
import { Navigator } from './Navigator';
import { IconAddProfessional } from '~/presentation/base/icons';
import { schemaContent } from '~/validation/validators/appointment/ExternalProfessional';

import { Button, Select } from '~/presentation/components/UI';
import { translator } from '~/presentation/components/i18n';
import { ListParticipatingProfessionals } from '../interConsultaListParticipant';
import {
  Content,
  StyleButton,
  Container,
  ContentSelects,
  ContainerButtonsGrid,
  ContentButtonsGrid,
  SelectAndButton,
} from './styles/styledProfessional';
import {
  iProfessional,
  iListSpeciality,
  iRegisterAppointment,
  iListProfessional,
} from './interface';
import { ConnectComponent } from './mapper/MapperProfessional';
import { AddAppointmentData } from '~/domain/usecases/appointment/redux/AddApointmentData';
import { makeReduxSetupProfession } from '~/main/factories/usecases/profession';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import { AlertMessage } from '../messages/AlertMessage';
import { closeModal } from '~/utils/closeModal';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

export interface externalProps {
  next: (data: iRegisterAppointment) => any;
  back: (data: iRegisterAppointment) => any;
  state?: iRegisterAppointment;
}

export interface ownProps {
  handlerState: AddAppointmentData;
  professionals: iListProfessional[];
  specialty: iListSpeciality[];
  filterProfessional: (id: number | undefined) => iListProfessional[];
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '50%',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const Professional: React.FC<ownProps & externalProps> = ({
  state,
  handlerState,
  professionals,
  specialty,
  filterProfessional,
  next,
  back,
}): JSX.Element => {
  const [specialtySelected, setSpecialtySelected] = useState(0);
  const [showProfessionals, setShowProfessionals] = useState<
    iListProfessional[]
  >([]);
  const [professionalId, setProfessionalId] = useState<string>('');
  const [previousProfessionalId, setPreviousProfessionalId] =
    useState<string>();
  const [checked, setChecked] = useState<boolean>(false);
  const [buttonStatus, setButtonStatus] = useState(false);
  const [participant, setParticipant] = useState<iProfessional[]>([]);
  const [saveIds, setSaveIds] = useState<number[]>([]);
  const [incrementCardId, setIncrementCardId] = useState<number>(0);
  const [stateModal, setStateModal] = useState<iProfessional>();
  const [update, setUpdate] = useState<iProfessional>({
    id: 0,
    name: '',
    specialty: '',
    type: '',
    phone: '',
    email: '',
  });

  const { results } = useSelector((store: iStore) => store.professions);
  const { info, selectUser } = useSelector((store: iStore) => store.auth);

  const { results: specialties } = useSelector(
    (store: iStore) => store.specialty,
  );

  const { errors, handleSubmit, register, setValue, getValues } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    resolver: zodResolver(schemaContent),
    defaultValues: {
      ...state,
    },
  });

  const onSubmit = handleSubmit(data => {
    next({ ...data });
  });

  const onBack = () => back({ ...getValues() });

  const handleZodUpdated = (prof: iProfessional[]) => {
    setValue('professionals', prof);
  };

  const handleIncrementId = (id: number) => {
    setIncrementCardId(id);
  };

  const handleZodModal = (prof: iProfessional) => {
    if (getValues().professionals) {
      setValue('professionals', [...getValues().professionals, prof]);
    } else {
      setValue('professionals', [prof]);
    }
  };

  const handleAdd = (id: string) => {
    if (id === previousProfessionalId) return;

    const professionalsArr = [...showProfessionals];

    const professionalIndex = professionalsArr.findIndex(
      item => `${item.user.firstName} ${item.user.lastName}` === id,
    );

    if (professionalIndex >= 0) {
      setPreviousProfessionalId(id);
      const newObj = {
        id: Number(professionalsArr[professionalIndex].professional.id),
        name: `${professionalsArr[professionalIndex].user.firstName} ${professionalsArr[professionalIndex].user.lastName}`,
        specialty: professionalsArr[professionalIndex].specialties[0].name,
        type: 'INTERNAL',
        specialtyId: professionalsArr[professionalIndex].specialties[0].id,
      };

      if (!newObj.name) return;

      if (newObj.id && !saveIds.includes(newObj.id)) {
        setSaveIds([...saveIds, newObj.id]);
      }

      setParticipant([
        ...participant,
        { ...newObj, cardId: incrementCardId + 1 },
      ]);

      setValue('professionals', [
        ...participant,
        { ...newObj, type: 'INTERNAL', cardId: incrementCardId + 1 },
      ]);

      setIncrementCardId(prevState => prevState + 1);

      setUpdate({
        id: 0,
        name: '',
        specialty: '',
        type: '',
        phone: '',
        email: '',
      });
      setButtonStatus(false);
    }
  };

  useEffect(() => {
    register('professionals');
    register('incrementalId');

    if (getValues) {
      if (getValues()?.incrementalId)
        setIncrementCardId(getValues()?.incrementalId!);

      if (getValues().professionals?.length) {
        const ids: number[] = [];

        getValues().professionals.map(item => item.id && ids.push(item.id));

        setSaveIds(ids);
        setParticipant(getValues()?.professionals);
      } else if (selectUser.role === 'PRO' && info.user && info.professionals) {
        const professional = info.professionals.find(
          item => item.orgUnit.id === selectUser.orgUnitId,
        );

        const newObj: iProfessional = {
          name: `${info.user.firstName} ${info.user.lastName}`,
          specialty: professional?.professions.specialties[0].name!,
          specialtyId: professional?.professions.specialties[0].id!,
          type: 'INTERNAL',
          cardId: incrementCardId + 1,
          id: professional?.id,
        };

        setSaveIds([...saveIds, professional?.id as number]);
        setParticipant([...participant, newObj]);
        setIncrementCardId(prevState => prevState + 1);
        setValue('professionals', [...participant, newObj]);
      }
    }
  }, [register]);

  useEffect(() => {
    setValue('incrementalId', incrementCardId);
  }, [incrementCardId]);

  useEffect(() => {
    console.log('error: ', errors);

    if (Object.keys(errors).length) {
      AlertMessage({
        message:
          (errors?.professionals as any)?.message !== 'Required'
            ? (errors?.professionals as any)?.message
            : intl.formatMessage({
                id: 'É preciso adicionar pelo menos um profissional',
              }),
        type: 'danger',
      });
    }
  }, [errors]);

  useEffect(() => {
    if (!stateModal?.name) return;
    setParticipant([...participant!, stateModal!]);
  }, [stateModal]);

  useEffect(() => {
    return () => {
      if (participant.length <= 0) return;
      const professionalMain = participant[0];
      const newUser: {
        name: string;
        specialty: string;
        phone: string;
        email: string;
        id: number;
      }[] = [];
      participant.forEach(user => {
        newUser.push({
          name: user?.name,
          specialty: user?.specialty,
          phone: user?.phone!,
          email: user?.email!,
          id: user?.id!,
        });
      });
      handlerState.add({
        data: {
          professional: professionalMain?.id,
          professionals: newUser,
        },
      });
    };
  }, [participant]);

  useEffect(() => {
    makeReduxSetupProfession().setup({
      professionId: -1,
    });
  }, []);

  useEffect(() => {
    setShowProfessionals(filterProfessional(specialtySelected));
  }, [specialtySelected]);

  return (
    <Container onSubmit={onSubmit}>
      <Content>
        <ContentSelects>
          <Select
            id="attendanceArea"
            width="500px"
            label={translator('Área do atendimento')}
            placeholder={translator('Selecione a área de atendimento')}
            onChange={e => {
              makeReduxSetupProfession().setup({
                professionId: Number(e.target.value),
              });
            }}
            required
          >
            <option value={-1}>
              {translator('Selecione a área de atendimento')}
            </option>
            {results.map(item => (
              <option value={Number(item.id)}>{item.name}</option>
            ))}
          </Select>
          <div style={{ display: 'flex', gap: '10px', alignItems: 'flex-end' }}>
            <Select
              id="appointmentProfessional"
              disabled={checked}
              label={translator('Profissional')}
              required
              value={professionalId}
              width="430px"
              onChange={e => {
                setProfessionalId(e.target.value);
                setButtonStatus(true);
              }}
            >
              <option id="default" value="select">
                {translator('Selecione um profissional')}
              </option>
              {showProfessionals?.map((pro, index) => {
                if (saveIds.includes(pro.professional.id!)) return null;
                return (
                  <option
                    id={`professional_${index}`}
                    value={`${pro.user.firstName} ${pro.user.lastName}`}
                  >
                    {`${pro.user.firstName} ${pro.user.lastName}`}
                  </option>
                );
              })}
            </Select>
            <StyleButton
              status={buttonStatus}
              id="btn_add"
              type="button"
              onClick={() => handleAdd(professionalId)}
            >
              +
            </StyleButton>
          </div>
        </ContentSelects>
        <SelectAndButton>
          <ContainerButtonsGrid>
            <ContentButtonsGrid>
              <Select
                id="appointmentSpeciality"
                name="specialty"
                width="485px"
                required
                label={translator('Especialidade')}
                onChange={e => setSpecialtySelected(Number(e.target.value))}
              >
                <option value={-1}>
                  {translator('Selecione uma especialidade')}
                </option>
                {specialty.map(item => (
                  <option value={Number(item.specialty?.id)}>
                    {item.specialty?.name}
                  </option>
                ))}
              </Select>
            </ContentButtonsGrid>
          </ContainerButtonsGrid>
          <div style={{ width: '100%', marginTop: '40px' }}>
            <Button
              id="btn_addExternalProfessional"
              variant="secundary"
              icon={IconAddProfessional}
              height="48px"
              size="bigger"
              onClick={() =>
                makeReduxActiveMessage().active({
                  active: 'inviteExternalProfessionals',
                  actionOk: () => closeModal(),
                  actionCancel: () => closeModal(),
                  handleUpdateValue: setStateModal,
                  handleZodUpdateValue: handleZodModal,
                  handleIncrementCardId: handleIncrementId,
                  incrementCardId,
                })
              }
            >
              {translator('Adicionar profissional externo')}
            </Button>
          </div>
        </SelectAndButton>
      </Content>
      <div style={{ width: '100%', marginTop: '20px' }}>
        <ListParticipatingProfessionals
          className="list"
          label={translator('Lista de profissionais participantes')}
          professionals={participant}
          setProfessionals={setParticipant}
          setIds={setSaveIds}
          ids={saveIds}
          setZodValue={handleZodUpdated}
        />
      </div>
      <Navigator back={onBack} />
    </Container>
  );
};

export default ConnectComponent(Professional);
