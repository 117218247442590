import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { createIntl, createIntlCache } from 'react-intl';
import { zodResolver } from '@hookform/resolvers/zod';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';
import { makeRemotePostUserConfirmation } from '~/main/factories/usecases/CreateUsersConfirmation/PostUsersConfirmation';
import history from '~/main/routes/History';
import {
  IconLogoNasNuvens,
  IconLogoRNP,
  IconNewLogoV4h,
  IconPadlock,
  IconV4Hatendimento,
} from '~/presentation/base/icons';
import { translator } from '~/presentation/components/i18n';
import { Icon } from '~/presentation/components/icon';
import { Button } from '~/presentation/components/UI';
import Input from '~/presentation/components/UI/input/index';
import Linkto from '~/presentation/components/UI/link';
import {
  Body,
  Container,
  Content,
  Footer,
  Header,
  Title,
  Form,
  Span,
  ContainerButtons,
  CancelButton,
} from './styles/CreateUsersConfirmationStyled';
import { PasswordValidationBar } from '~/presentation/components/passwordValidationBar';
import { schema } from '~/validation/validators/usersConfirmation/userConfirmationPassword';
import { iUsersConfirmation } from './interface';
import { PatchPasswordRecovery as PasswordValidate } from '~/domain/usecases/password/remote';
import { PostUsersConfirmation as UserConfirmation } from '~/domain/usecases/createUserConfirmation/remote';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { FooterImgs, Image } from '../LoginPage/styles/LoginPageStyled';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

const CreateUsersConfirmation: React.FC = (): JSX.Element => {
  const [usersConfirmation, setUsersConfirmation] =
    useState<iUsersConfirmation>({
      password: '',
      token: '',
    });
  const [passconfirm, setPassConfirm] = useState<string>('');
  const [erro, setErro] = useState<boolean>(false);
  const [passToken, setPassToken] = useState(false);
  const query = new URLSearchParams(useLocation().search);
  const tokenResult = query.get('token');
  const passwordResult = String(query.get('password'));

  const today = new Date();

  const { errors, handleSubmit, register, setValue, getValues } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    resolver: zodResolver(schema),
    defaultValues: {
      ...usersConfirmation,
    },
  });

  const footerImages = [
    {
      icon: IconLogoRNP,
      name: 'rnp-icon',
    },
    {
      icon: IconLogoNasNuvens,
      name: 'nasnuvens-icon',
    },
  ];

  useEffect(() => {
    if (passwordResult === 'false' || passwordResult === 'null') {
      const dataToSend: UserConfirmation.Params = {
        token: tokenResult!,
      };

      const validate = schema.parse(dataToSend);

      try {
        makeRemotePostUserConfirmation()
          .post({
            token: tokenResult!,
          })

          .catch(() =>
            AlertMessage({
              message: intl.formatMessage({
                id: 'Você realmente solicitou a criação de conta?',
              }),
              type: 'danger',
            }),
          );
      } catch (e) {
        console.error(e);
      }
    }
  }, [passwordResult]);

  useEffect(() => {
    setUsersConfirmation({ ...usersConfirmation, token: tokenResult! });
  }, []);

  useEffect(() => {
    if (passwordResult === 'false' || passwordResult === 'null') {
      setPassToken(false);
    } else {
      setPassToken(true);
    }
  }, [passwordResult]);

  useEffect(() => {
    if (usersConfirmation.password === passconfirm) {
      setErro(false);
    } else {
      setErro(true);
    }
  }, [passconfirm]);

  const userConfirmation = async () => {
    if (
      usersConfirmation.password === passconfirm &&
      usersConfirmation.password !== '' &&
      tokenResult !== null &&
      passToken === true
    ) {
      const dataToSend: UserConfirmation.Params = {
        password: usersConfirmation.password,
        token: tokenResult!,
      };

      try {
        const validate = schema.parse(dataToSend);

        await makeRemotePostUserConfirmation()
          .post({
            password: usersConfirmation.password,
            token: tokenResult!,
          })
          .then(() => {
            setTimeout(() => history.push('/'), 1200);
          })
          .catch(() =>
            AlertMessage({
              message: intl.formatMessage({
                id: 'Você realmente solicitou a criação de conta?',
              }),
              type: 'danger',
            }),
          );
      } catch (e) {
        console.error(e);
      }
    } else if (passToken === false && tokenResult !== null) {
      const dataToSend: UserConfirmation.Params = {
        token: tokenResult!,
      };
      try {
        const validate = schema.parse(dataToSend);

        makeRemotePostUserConfirmation()
          .post({ token: tokenResult! })
          .catch(() =>
            AlertMessage({
              message: intl.formatMessage({
                id: 'Você realmente solicitou a criação de conta?',
              }),
              type: 'danger',
            }),
          );
      } catch (e) {
        console.error(e);
      }
    } else if (
      passToken === true &&
      usersConfirmation.password === '' &&
      passconfirm === ''
    ) {
      AlertMessage({
        message: intl.formatMessage({
          id: 'Digite e confirme sua nova senha.',
        }),
        type: 'danger',
      });
    } else if (passToken === true && passconfirm === '') {
      AlertMessage({
        message: intl.formatMessage({
          id: 'Digite e confirme sua nova senha.',
        }),
        type: 'danger',
      });
    } else {
      AlertMessage({
        message: intl.formatMessage({
          id: 'Você realmente é um novo usuário?',
        }),
        type: 'danger',
      });
    }
  };

  function onChangePassword(value: string) {
    setUsersConfirmation({ ...usersConfirmation, password: value });
    setValue('password', value);
  }

  function backLogin() {
    history.push('/');
  }

  function goBack() {
    history.push('/');
  }

  const onSubmit = handleSubmit(data => {
    setUsersConfirmation({ ...usersConfirmation, ...data });
  });

  return (
    <Container onSubmit={onSubmit}>
      <Body>
        <Content id="validateRestoreAuthentication">
          {passToken ? (
            <>
              <IconNewLogoV4h />
              <Title>Confirmando a criação de conta</Title>
              <Span>
                {translator(
                  'Digite e confirme sua nova senha nos campos abaixo.',
                )}
              </Span>
              <ContainerButtons>
                <Input
                  id="passwordCreateInput"
                  name="createPassword"
                  type="password"
                  label="Senha"
                  onChange={e => onChangePassword(e.target.value)}
                  register={() => register('password')}
                  error={Boolean(errors.password)}
                  message={errors?.password?.message}
                  required
                  placeholder="Digite sua senha"
                  autoFocus
                  icon={IconPadlock}
                />
                <Input
                  error={erro}
                  id="passwordCreate"
                  type="password"
                  name="validateCreatePassword"
                  required
                  onChange={e => setPassConfirm(e.target.value)}
                  value={passconfirm}
                  label="Confirmar sua senha"
                  placeholder="Digite sua senha"
                  icon={IconPadlock}
                />

                <Button
                  onClick={userConfirmation}
                  id="restoreSubmit"
                  type="submit"
                  rounded
                  height="48px"
                >
                  Concluir
                </Button>
                <CancelButton onClick={goBack} id="cancelSubmit" type="submit">
                  Cancelar
                </CancelButton>
              </ContainerButtons>
            </>
          ) : (
            <div style={{ margin: '50px auto' }}>
              <div
                style={{
                  marginBottom: '80px',
                  textAlign: 'center',
                  maxWidth: '280px',
                  fontSize: '16px',
                  color: '#004ba5',
                  fontWeight: 'bold',
                }}
              >
                A criação da conta já foi confirmada! Clique no botão abaixo
                caso deseje fazer login.
              </div>
              <Button
                id="restoreSubmit"
                type="submit"
                rounded
                height="48px"
                onClick={backLogin}
              >
                Fazer login
              </Button>
            </div>
          )}
        </Content>
      </Body>
      <Footer>
        <div className="links">
          {`© ${today.getFullYear()} Copyright | `}
          <Linkto
            to="https://minio.v4h.cloud/public/V4H_Poli%CC%81tica_de_Privacidade.pdf"
            title={translator('Política de privacidade')}
          />
          e
          <Linkto
            to="https://minio.v4h.cloud/public/V4H_Termos_de_Uso.pdf"
            title={translator('Termos de uso')}
          />
        </div>
      </Footer>
    </Container>
  );
};

export default CreateUsersConfirmation;
