import { makeHttpClient } from '~/main/factories/http';
import { GetUfs as UsecaseRemoteGetUfs } from '~/domain/usecases/externalServices/remote';

import { RemoteGetUfs } from '~/data/repository/externalServices/RemoteGetUfs';

/**
 * send request via API.
 */
export const makeRemoteGetUfs = (): UsecaseRemoteGetUfs =>
  new RemoteGetUfs(
    'https://servicodados.ibge.gov.br/api/v1/localidades/estados',
    makeHttpClient(),
  );
