import React from 'react';
import { translator } from '../i18n';
import { AdmListStatusProps } from './interface';

import { Container, Status } from './styles/StyledAdmListStatus';

const AdmListStatus: React.FC<AdmListStatusProps> = ({ status }) => {
  return (
    <Container>
      {status === 'ACTIVE' && (
        <Status status={status}>{translator('Ativo')}</Status>
      )}
      {status === 'INACTIVE' && (
        <Status status={status}>{translator('Inativo')}</Status>
      )}
      {status === 'SUSPENDED' && (
        <Status status={status}>{translator('Suspenso')}</Status>
      )}
    </Container>
  );
};

export default AdmListStatus;
