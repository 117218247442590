import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  height: 100%;
  min-height: 768px;
  display: flex;
  align-items: center;
  justify-content: center;

  iframe {
    height: 100%;
  }
`;
