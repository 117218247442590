import { object, string } from 'zod';

export const schema = object({
  name: string().nonempty({ message: 'Nome completo é obrigatório.' }),
  cpf: string().min(11).nonempty({ message: 'CPF é obrigatório.' }),
  email: string()
    .email({ message: 'Email inválido.' })
    .nonempty({ message: 'Email é obrigatório.' }),
  orgName: string().nonempty({ message: 'Nome da organização é obrigatório.' }),
  phone: string()
    .max(14)
    .min(11, { message: 'Telefone inválido.' })
    .nonempty({ message: 'Telefone é obrigatório.' }),
});
