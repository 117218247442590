import { createIntlCache, createIntl } from 'react-intl';
import { UsersTypes } from '~/domain/interfaces/redux/users/types';
import { active as activeMessage } from '~/data/store/reducer/message/actions/active';
import { MessageOptions } from '~/domain/interfaces/redux/message/types';
import { History } from '~/main/routes';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

/**
 * Middleware to handler the user message.
 * @param action action received by redux.
 * @param next function to pass the action.
 */
export const UserNotifications = (
  action: any,
  next: any,
  closeNotification: any,
) => {
  const { type } = action;

  // TODO: Atualizar traduções modais de actions, sem utilização de Hooks

  switch (type) {
    case UsersTypes.CREATE_SUCCESS:
      next(
        activeMessage({
          active: MessageOptions.userCreateSuccess,
          actionOk: () => {
            next(closeNotification);
            History.back();
          },
        }),
      );
      break;
    case UsersTypes.UPDATE_SUCCESS:
      next(
        activeMessage({
          active: MessageOptions.success,
          content: intl.formatMessage({ id: 'Alterações salvas com sucesso!' }),
          actionOk: () => {
            next(closeNotification);
          },
        }),
      );
      break;
    case UsersTypes.DELETE:
      return next(
        activeMessage({
          active: MessageOptions.delete,
          title: `${intl.formatMessage({ id: 'Excluir usuario' })}?`,
          content: intl.formatMessage({
            id: 'Ao excluir um usuário, as informações pertinentes a eles como dados de atendimentos, documentos clínicos e de apoio como também as conversas em chat, serão mantidas de acordo com as politicas de privacidade e termos de uso da plataforma V4H atendimento. ',
          }),
          actionOk: () => {
            next(closeNotification);
            next(action);
          },
          actionCancel: () => {
            next(closeNotification);
          },
        }),
      );
    case UsersTypes.DELETE_SUCCESS:
      return next(
        activeMessage({
          active: MessageOptions.success,
          content: intl.formatMessage({ id: 'Usuario excluido com sucesso!' }),
          actionOk: () => {
            next(closeNotification);
            next(action);
          },
        }),
      );
    case UsersTypes.LEAVE_SPECIALTY:
      return next(
        activeMessage({
          active: MessageOptions.leaveSpecialty,
          actionOk: () => {
            next(closeNotification);
            next(action);
          },
        }),
      );
    default:
      break;
  }
  return next(action);
};
