import React, { ComponentType } from 'react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { iListProfessional, Specialty, iListProfession } from '../interface';

import { ownProps, externalProps } from '../ProfessionalData';

/**
 * Connect component.
 * @param Component componente to connect.
 */
export function ConnectComponent<P>(
  Component: ComponentType<P & ownProps>,
): React.FC<P & externalProps> {
  const Render: React.FC<P & externalProps> = ({ ...rest }) => {
    const { specialty, professions } = useSelector((state: iStore) => state);

    Component.defaultProps = {
      profession: MapProfession(professions.results),
      filterSpecialty: (id: number | undefined) =>
        filterSpecialty(id, specialty.results),
      ...(rest as P),
    };

    return <Component {...(rest as P & ownProps & externalProps)} />;
  };

  return Render;
}

/**
 * Map list the profession to component.
 * @param profession list of profession.
 */
export const MapProfession = (
  profession: iListProfession[],
): iListProfession[] => {
  const arr: iListProfession[] = [];

  profession.forEach(item => {
    arr.push({
      id: item.id,
      descr: item.descr,
      name: item.name,
      docProfName: item.docProfName,
    });
  });

  return arr;
};

/**
 * filter professional based specialty ID.
 * @param id profession ID.
 * @param specialty list of specialty.
 */
const filterSpecialty = (
  id: number | undefined,
  specialty: Specialty[],
): Specialty[] => {
  const arr: Specialty[] = specialty.filter(
    prof => prof.specialty?.profession === id,
  );
  return arr;
};
