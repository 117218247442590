import styled from 'styled-components';

export const Container = styled.div`
  max-width: 300px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  background: #fbfbfb;
  border: 1px solid #efefef;
  overflow: scroll;
`;

export const Name = styled.div`
  margin-top: 5px;
  font-size: 16px;
`;

export const Role = styled.div`
  font-size: 14px;
  color: #7a7a7a;
`;

export const Actions = styled.div`
  max-width: 170px;
  width: 100%;
  margin-top: 20px;
  display: grid;
  grid-gap: 10px;
`;

export const Exit = styled.button`
  max-width: 170px;
  max-height: 35px;
  width: 100%;
  height: 100%;
  border: 1px solid red;
  border-radius: 10px;
  background: transparent;
  color: red;
  border-radius: 5px;
  margin-top: 40px;
`;
