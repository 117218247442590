/* eslint-disable consistent-return */
/* eslint-disable no-case-declarations */
import { TableSortLabel } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { makeReduxDeleteUser } from '~/main/factories/usecases/users/DeleteUserFactory';
import { History } from '~/main/routes';
import { IconSort } from '~/presentation/base/icons';
import { translator } from '~/presentation/components/i18n';
import orderData from '~/utils/orderData';
import ActionsList from '../../actionsList/ActionsList';
import { AvatarBadger } from '../../avatar-roles';
import { iListRegisteredParticipant } from '../../ListProfessional';
import ListSkeleton from '../../skeleton/ListSkeleton';
import { ScheduleStatus } from '../../statusList';
import { Avatar } from '../styles/StyledUserItem';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  container: {
    maxHeight: '100vh',
    overflowY: 'auto',
  },
});

interface ownProps {
  dataList: iListRegisteredParticipant[];
}

type iOrder = 'date' | 'fullName' | 'age';
const RegisteredParticipant: React.FC<ownProps> = props => {
  /**
   * @order parameter to sort 'asc' or 'desc'
   */
  const [order, setOrder] = useState<boolean>(true);
  const [orderByName, setOrderByName] = useState<boolean>(true);
  const [categoryToOrder, setCategoryToOrder] = useState(true);
  const [data, setData] = useState<iListRegisteredParticipant[]>(
    props.dataList,
  );
  const [dataPagination, setDataPagination] = useState();
  const { loading } = useSelector((store: iStore) => store.users);

  const arrToCompare = ['date', 'fullName', 'age'];
  const classes = useStyles();

  const columns = [
    {
      id: 'cod',
      label: translator('Cod'),
      minWidth: 20,
      align: 'left',
    },
    {
      id: 'date',
      label: translator('Data de inclusão'),
      minWidth: 50,
      maxWidth: 50,
      align: 'left',
    },
    {
      id: 'fullName',
      label: translator('Nome completo'),
      minWidth: 190,
      maxWidth: 300,
      align: 'left',
    },
    {
      id: 'age',
      label: translator('Idade'),
      minWidth: 120,

      align: 'left',
    },
    {
      id: 'phone',
      label: translator('Contato'),
      minWidth: 100,

      align: 'left',
    },
    {
      id: 'email',
      label: translator('E-mail'),
      minWidth: 20,
      align: 'left',
    },
    {
      id: 'actions',
      label: translator('Ações'),
      minWidth: 20,
      align: 'left',
    },
  ];

  function countMonths(item: any) {
    const today = Date.now();
    const birthDate = new Date(item);

    const diffTime = Math.abs(today - Number(birthDate));
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    const age = Math.floor(diffDays / 365);
    let months = ((diffDays / 365) % 1) * 12;
    months = Math.trunc(months);

    return `${age} ${translator('anos')} ${months} ${translator('meses')}`;
  }

  function handleSortByCategory(type: iOrder) {
    switch (type) {
      case 'date':
        const orderStatus = categoryToOrder ? 'asc' : 'desc';

        setData([
          ..._.orderBy(data, dateSort => dateSort.date, `${orderStatus}`),
        ]);
        return setCategoryToOrder(!categoryToOrder);

      default:
        setOrder(prevState => !prevState);
        setOrderByName(!orderByName);

        setOrder(prevState => !prevState);
        
        return setData(
          _.orderBy(
            data,
            dateSort => dateSort.fullName.toLowerCase(),
            orderByName ? 'asc' : 'desc',
          ),
        );
    }
  }

  useEffect(() => {
    setOrder(false);
    setData([...props.dataList]);
  }, [props.dataList]);

  const RenderComponents: React.FC<{ id: any; item: any }> = ({ id, item }) => {
    switch (id) {
      case 'actions':
        return (
          <ActionsList
            actions={{
              details: () => {
                const url = window.location.pathname;
                History.getHistory().push(`/profile/${item.userId}`, {
                  from: url.replaceAll('/', ''),
                  id: item.userId,
                });
              },
              delete: () => makeReduxDeleteUser().delete({ id: item.userId }),
            }}
          />
        );
      case 'status':
        return <ScheduleStatus status={item.status} />;
      case 'fullName':
        return (
          <Avatar>
            <AvatarBadger
              src={item.avatar}
              name={item.professional}
              size="root"
              status={false}
            />
            <text>{item.fullName}</text>
          </Avatar>
        );
      case 'age':
        const age = countMonths(item.age);
        return <div>{`${age}`}</div>;
      default:
        return <div>{item[`${id}`]}</div>;
    }
  };

  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {columns.map(item => {
              return (
                <TableCell align="left">
                  {arrToCompare.includes(item.id) ? (
                    <TableSortLabel
                      active
                      IconComponent={() => (
                        <div style={{ paddingLeft: 3 }}>
                          <IconSort id={`sort_${item.id}`} />
                        </div>
                      )}
                      style={{ color: '#8A8A8A', fontWeight: 'normal' }}
                      onClick={() => handleSortByCategory(item.id as iOrder)}
                    >
                      {item.label}
                    </TableSortLabel>
                  ) : (
                    <div style={{ color: '#8A8A8A', fontWeight: 'normal' }}>
                      {item.label}
                    </div>
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <>
              {columns.map(item => {
                return (
                  <TableRow>
                    <ListSkeleton columns={columns} />
                  </TableRow>
                );
              })}
            </>
          ) : (
            data.map((item: iListRegisteredParticipant) => (
              <TableRow key={item.cod}>
                {columns.map((columnProps: any) => {
                  return (
                    <TableCell
                      align={columnProps.align}
                      style={{
                        maxWidth: columnProps.maxWidth,
                        minWidth: columnProps.minWidth,
                      }}
                    >
                      <RenderComponents id={columnProps.id} item={item} />
                    </TableCell>
                  );
                })}
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default RegisteredParticipant;
