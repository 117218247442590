import React, { useEffect, useState } from 'react';

import { HeaderSimples } from '~/presentation/components/header';
import { SearchListConsultant } from '~/presentation/components/search-list';
import { Button } from '~/presentation/components/UI';
import { Menu } from '~/presentation/components/menu';
import ListRegisteredParticipant from '../../../../components/ListManager/ListRegisteredParticipant';

import {
  Container,
  Body,
  HeaderList,
  Wrapper,
  Attendiment,
} from './StyledSchedule';
import { History } from '~/main/routes';
import { translator } from '~/presentation/components/i18n';
import { IconPlus } from '~/presentation/base/icons';
import { TablePagination } from '~/presentation/components/tablePagination';
import { ConnectParticipant } from '~/presentation/components/ListManager/mapper/MapperListParticipant';
import { iListRegisteredParticipant } from '~/presentation/components/ListManager';
import { FilterPatient } from '~/presentation/components/filter';
import { makeReduxDataPagination } from '~/main/factories/usecases/dataPagination/SetDataFactory';

export interface pageProps {
  data: iListRegisteredParticipant[];
}
const ScheduleParticipant: React.FC<pageProps> = ({ data }): JSX.Element => {
  const [state, setState] = useState(data);
  const url = window.location.pathname;
  useEffect(() => {
    if (data.length === 0) {
      makeReduxDataPagination().set([]);
    }
    setState(data);
  }, [data]);

  function renderTablePagination() {
    if (state.length > 0 && state !== undefined) {
      return <TablePagination data={state} itemPerPage={10} />;
    }
    return <div />;
  }
  return (
    <Container>
      <Menu />
      <HeaderSimples
        title={translator('Pacientes cadastrados')}
        value={state.length}
        valueDescription={translator('Cadastrados')}
      />

      <Body>
        <HeaderList>
          {renderTablePagination()}
          <Wrapper>
            <div>
              <SearchListConsultant>
                <FilterPatient />
              </SearchListConsultant>
            </div>
            <Button
              id="button_create"
              onClick={() =>
                History.getHistory().push('/participant/register', {
                  from: url.replaceAll('/', ''),
                })
              }
              size="large"
              rounded
            >
              <Attendiment>
                <IconPlus />
              </Attendiment>
              <text>{translator('Novo paciente')}</text>
            </Button>
          </Wrapper>
        </HeaderList>
        <ListRegisteredParticipant />
      </Body>
    </Container>
  );
};

export default ConnectParticipant(ScheduleParticipant);
