import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';

import { zodResolver } from '@hookform/resolvers/zod';

import { useHistory } from 'react-router';
import Input from '~/presentation/components/UI/input/index';
import { translator } from '~/presentation/components/i18n';
import { Button } from '~/presentation/components/UI';
import { Icon } from '~/presentation/components/icon';
import {
  IconOfferOne,
  IconOfferThree,
  IconOfferTwo,
  IconSmallLogoV4H,
} from '~/presentation/base/icons';
import {
  Container,
  Left,
  Right,
  Form,
  Body,
  ButtonsContainer,
  Title,
  Offerings,
  Offer,
  WiseServices,
  Image,
  ImageContainer,
} from './styles/StyledFreemiumRegister';

import { V4hSpin } from '~/presentation/components/spin';
import { iStore } from '~/domain/interfaces/models';

import { schema } from '~/validation/validators/auth/FreemiumValidator';
import { makeRemoteCreateFreemium } from '~/main/factories/usecases/selectedOrg/CreateFreemium';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';

const FreemiumRegister: React.FC = (): JSX.Element => {
  const { push } = useHistory();

  const results = useSelector((store: iStore) => store.selectedOrg.results);

  const [logoUrl, setLogoUrl] = useState<string | null>(null);

  const { register, handleSubmit, errors, control, setValue, watch } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    resolver: zodResolver(schema),
    defaultValues: {
      name: '',
      cpf: '',
      orgName: '',
      email: '',
      phone: '',
    },
  });

  const onSubmit = handleSubmit(({ name, cpf, email, orgName, phone }) => {
    const firstName = name.split(' ')[0];
    const lastName = name.split(' ').slice(1).join(' ');

    makeRemoteCreateFreemium()
      .create({
        org: {
          name: orgName,
          type: 'F',
          reg: cpf.replace(/\D/g, ''),
          system: 'V4H Atendimento',
          urlregex: 'https://atendimento2.dev.v4h.cloud',
        },
        user: {
          firstName,
          lastName,
          email,
          phone,
          cpf: cpf.replace(/\D/g, ''),
          gender: 'OTHER',
        },
      })
      .then(res => {
        console.log('res: ', res);

        AlertMessage({
          message: 'Cadastro realizado com sucesso, verifique seu e-mail!',
          type: 'success',
        });

        push('/');
      })
      .catch(err => {
        console.log('err: ', err);

        AlertMessage({
          message: 'Erro ao realizar cadastro!',
          type: 'danger',
        });
      });
  });

  console.log('errors: ', errors);

  const loading = useSelector((store: iStore) => store.auth.loading);

  useEffect(() => {
    if (results.length > 0 && results[0].org?.logo)
      setLogoUrl(results[0].org.logo);
  }, [results]);

  return (
    <Container>
      <Body>
        <Left>
          <Offerings>
            {logoUrl ? (
              <Image id="logoV4H" alt="Logo" src={logoUrl} width="200px" />
            ) : (
              <ImageContainer>
                <Icon src={IconSmallLogoV4H} width="260px" height="130px" />
              </ImageContainer>
            )}
            <span className="text">
              Utilize a nossa plataforma por um período gratuito de 14 dias.
            </span>
            <WiseServices>O que o V4H Atendimento pode oferecer</WiseServices>
            <Offer>
              <Icon src={IconOfferOne} width="64px" height="64px" />
              <span>
                Criação e envio de receitas médicas, atestados, relatórios e
                requisição de exames.
              </span>
            </Offer>
            <Offer>
              <Icon src={IconOfferTwo} width="64px" height="64px" />
              <span>
                Agendamento, organização e realização de atendimentos em um só
                lugar.
              </span>
            </Offer>
            <Offer>
              <Icon src={IconOfferThree} width="64px" height="64px" />
              <span>
                Sistema com segurança de envio e armazenamento de dados em
                Blockchain.
              </span>
            </Offer>
          </Offerings>
        </Left>
        <Right>
          <Form id="loginAuthentication" onSubmit={onSubmit}>
            <Title>Cadastro</Title>
            <Input
              id="name"
              type="text"
              name="name"
              label={translator('Nome Completo')}
              placeholder={translator('Digite seu nome completo')}
              register={() => register('name')}
              onChange={e => setValue('name', e.target.value.trim())}
              error={Boolean(errors.name)}
              message={
                errors?.name?.message ? translator(errors?.name?.message) : ''
              }
              required
            />

            <InputMask
              mask="999.999.999-99"
              onChange={e => setValue('cpf', e.target.value.trim())}
              value={watch('cpf')}
            >
              <Input
                id="cpf"
                type="text"
                name="cpf"
                label={translator('CPF')}
                placeholder={translator('Digite seu cpf')}
                register={() => register('cpf')}
                error={Boolean(errors.cpf)}
                message={
                  errors?.cpf?.message ? translator(errors?.cpf?.message) : ''
                }
                required
              />
            </InputMask>

            <Input
              id="email"
              type="text"
              name="email"
              label={translator('E-mail')}
              placeholder={translator('Digite o seu email')}
              register={() => register('email')}
              onChange={e =>
                setValue('email', e.target.value.toLowerCase().trim())
              }
              error={Boolean(errors.email)}
              message={
                errors?.email?.message ? translator(errors?.email?.message) : ''
              }
              required
            />

            <Input
              id="orgName"
              type="text"
              name="orgName"
              label={translator('Nome da empresa ou organização')}
              placeholder={translator(
                'Digite o nome da empresa ou organização',
              )}
              register={() => register('orgName')}
              onChange={e => setValue('orgName', e.target.value.trim())}
              error={Boolean(errors.orgName)}
              message={
                errors?.orgName?.message
                  ? translator(errors?.orgName?.message)
                  : ''
              }
              required
            />

            <InputMask
              mask="(99) 99999-9999"
              name="phone"
              ref={() => register('phone')}
              value={watch('phone')}
              onChange={e => {
                const phone = e.target.value.replace(/[^\d]+/g, '').trim();
                setValue('phone', phone);
              }}
            >
              <Input
                id="phone"
                name="phone"
                placeholder="(DDD) 99999-9999"
                label={translator('Telefone')}
                error={Boolean(errors.phone)}
                message={
                  errors?.phone?.message
                    ? translator(errors?.phone?.message)
                    : ''
                }
                required
              />
            </InputMask>

            <ButtonsContainer>
              <Button
                id="signupSubmit"
                type="submit"
                size="bigger"
                rounded
                height="48px"
              >
                Criar minha conta
              </Button>
            </ButtonsContainer>
          </Form>
        </Right>
        {loading && <V4hSpin fullScreen />}
      </Body>
    </Container>
  );
};

export default FreemiumRegister;
