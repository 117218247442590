import { Skeleton } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { iStore } from '~/domain/interfaces/models';
import { makeReduxDataPagination } from '~/main/factories/usecases/dataPagination/SetDataFactory';
import { DocumentCross } from '~/presentation/base/icons';
import { FilterClinicalDocs } from '~/presentation/components/filter';
import { HeaderSimples } from '~/presentation/components/header';
import { translator } from '~/presentation/components/i18n';
import {
  iListClinicalDocument,
  ListClinicalDocument,
} from '~/presentation/components/ListParticipant';
import { ConnectClinicalDocs } from '~/presentation/components/ListParticipant/mapper/MapperListClinicalDocument';
import { Menu } from '~/presentation/components/menu';
import { SearchListClinicalDocs } from '~/presentation/components/search-list';
import { TablePagination } from '~/presentation/components/tablePagination';
import { Body, Container, HeaderList, Wrapper } from './StyledSchedules';

export interface pageProps {
  data: iListClinicalDocument[];
}

const ClinicalDocuments: React.FC<pageProps> = ({ data }): JSX.Element => {
  const { loading } = useSelector((store: iStore) => store.clinicalDocs);
  const [state, setState] = useState(data);
  useEffect(() => {
    if (data.length === 0) {
      makeReduxDataPagination().set([]);
    }
    setState(data);
  }, [data]);

  return (
    <Container>
      {/* <MenuDefault /> */}
      <Menu />
      <HeaderSimples
        title={`${translator('Documentos clínicos')}`}
        value={state.length}
        valueDescription="documentos"
        icon={DocumentCross}
      />
      <Body>
        <HeaderList>
          {loading ? (
            <Skeleton variant="rect" width="120px" />
          ) : (
            <>
              {state.length > 0 && state !== undefined ? (
                <TablePagination data={state} itemPerPage={10} />
              ) : (
                <div />
              )}
            </>
          )}
          <Wrapper>
            <div>
              <SearchListClinicalDocs>
                <FilterClinicalDocs />
              </SearchListClinicalDocs>
            </div>
          </Wrapper>
        </HeaderList>
        <ListClinicalDocument />
      </Body>
    </Container>
  );
};

export default ConnectClinicalDocs(ClinicalDocuments);
