import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Input, Select, Button } from '~/presentation/components/UI';

import { getAppointmentsByDate } from '~/utils/getAppointmentsByDate';
import {
  Container,
  ContainerButtons,
  ContainerForm,
  FormItem,
  ClinicalDocs,
  ContainerDocs,
  FormGroup,
  Options,
} from './styles/StyledFilterAppointment';

import { translator } from '~/presentation/components/i18n';
import { iStore } from '~/domain/interfaces/models';

const FilterAppointment: React.FC = () => {
  // const dateOne = new Date();
  // const dateTwo = new Date();

  const [consultant, setConsultant] = useState('');
  const [professional, setProfessional] = useState('');
  const [docType, setDocType] = useState<string>('DEFAULT');
  const [hasClinDoc, setHasClinDoc] = useState(false);
  const [hasSuppDoc, setHasSuppDoc] = useState(false);
  const [state, setState] = useState<string>('DEFAULT');
  // const [created, setCreated] = useState(dateOne);
  // const [endAt, setEndAt] = useState(dateTwo);

  const { auth, appointment } = useSelector((store: iStore) => store);

  const { role, orgId, orgUnitId } = useSelector(
    (store: iStore) => store.auth.selectUser,
  );

  const handleFilter = () => {
    const consultantId = auth.info.consultants?.find(
      item => item.org?.id === orgId,
    )?.id;

    const professionalId = auth.info.professionals?.find(
      item => item.orgUnit.id === orgUnitId,
    )?.id;

    getAppointmentsByDate(appointment.date.toISOString(), auth.info, {
      professional: role === 'PRO' ? professionalId : undefined,
      consultant: role === 'CON' ? consultantId : undefined,
      consultantName: consultant,
      professionalName: professional,
      type: docType === 'DEFAULT' ? undefined : docType,
      hasDocSup: hasSuppDoc || undefined,
      hasDocClin: hasClinDoc || undefined,
      status: state === 'DEFAULT' ? undefined : state,
    });

    document.getElementById('close_filter')?.click();
  };

  const resetFilter = () => {
    setConsultant('');
    setProfessional('');
    setDocType('DEFAULT');
    setHasClinDoc(false);
    setHasSuppDoc(false);
    setState('DEFAULT');

    getAppointmentsByDate(appointment.date.toISOString(), auth.info);
  };

  return (
    <Container>
      <ContainerForm
        onSubmit={e => {
          e.preventDefault();
          handleFilter();
        }}
      >
        {(role === 'ORG' || role === 'ADM' || role === 'CON') && (
          <FormItem>
            <p>{`${translator('Profissional')}`}</p>
            <Input
              id="input_professional"
              placeholder={`${translator('Digite o nome do profissional')}`}
              className="hover-input"
              value={professional}
              autoFocus
              onChange={e => setProfessional(e.target.value)}
            />
          </FormItem>
        )}
        {role !== 'CON' && (
          <FormItem>
            <p>{`${translator('Paciente')}`}</p>
            <Input
              id="input_patient"
              placeholder={`${translator('Digite o nome do paciente')}`}
              className="hover-input"
              value={consultant}
              autoFocus={role === 'PRO' || false}
              onChange={e => setConsultant(e.target.value)}
            />
          </FormItem>
        )}
        {/* <FormItem>
          <p>{`${translator('Período')}`}</p>
          <FormGroup>
            <InputKeyBoardDate
              id="input_date"
              defaultValue="dd/MM/yyyy"
              state={created}
              setState={setCreated}
              placeholder="Data"
            />
            <InputKeyBoardDate
              id="input_date"
              defaultValue="dd/MM/yyyy"
              state={endAt}
              setState={setEndAt}
              placeholder={`${translator('Fim')}`}
            />
          </FormGroup>
        </FormItem> */}
        <FormItem>
          <p>{`${translator('Tipo')}`}</p>
          <Select
            id="select_type"
            value={docType}
            placeholder={`${translator('Tipo')}`}
            onChange={e => setDocType(e.target.value)}
          >
            <Options id="type_1" value="DEFAULT">
              {`${translator('Qualquer')}`}
            </Options>
            <Options id="type_2" value="FIRST">
              {`${translator('Atendimento')}`}
            </Options>
            <Options id="type_3" value="RETURN">
              {`${translator('Retorno')}`}
            </Options>
          </Select>
        </FormItem>
        <FormItem>
          <p>Status</p>
          <Select
            id="select_status"
            value={state}
            onChange={e => setState(e.target.value)}
            placeholder={`${translator('Qualquer')}`}
          >
            <Options id="state_0" value="DEFAULT">
              {translator('Qualquer')}
            </Options>
            {/* <Options id="state_1" value="PENDING">
              Pendente
            </Options> */}
            <Options id="state_2" value="SCHEDULED">
              {translator('Agendado')}
            </Options>
            <Options id="state_3" value="INPROGRESS">
              {translator('Em progresso')}
            </Options>
            <Options id="state_4" value="CANCELED">
              {translator('Cancelado')}
            </Options>
            <Options id="state_5" value="ENDED">
              {translator('Finalizado')}
            </Options>
          </Select>
        </FormItem>
        <FormItem>
          <p>{`${translator('Ações')}`}</p>
          <ContainerDocs>
            {/* {`${translator('Gravados')}`} */}
            {/* <input id="checkbox_recorded" type="checkbox" /> */}
            {`${translator('Documentos de apoio')}`}
            <input
              id="checkbox_supportingDocs"
              type="checkbox"
              checked={hasSuppDoc}
              onChange={e => setHasSuppDoc(e.target.checked)}
            />
          </ContainerDocs>
        </FormItem>
        <ClinicalDocs>
          {`${translator('Documentos Clínicos')}`}
          <input
            id="checkbox_clinicalDocs"
            type="checkbox"
            checked={hasClinDoc}
            onChange={e => setHasClinDoc(e.target.checked)}
          />
        </ClinicalDocs>
      </ContainerForm>
      <ContainerButtons>
        <Button
          rounded
          variant="secundary"
          size="medium"
          onClick={() => resetFilter()}
        >
          {`${translator('Redefinir')}`}
        </Button>
        <Button
          id="btn_search"
          // resourcers={['PARTICIPANT', 'APPOINTMENT']}
          // actions={['READ_OWN']}
          rounded
          variant="primary"
          size="medium"
          onClick={() => handleFilter()}
          autoFocus
        >
          {`${translator('Pesquisar')}`}
        </Button>
      </ContainerButtons>
    </Container>
  );
};

export default FilterAppointment;
