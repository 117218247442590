import { boolean, number, object, string, enum as a, array } from 'zod';
import { validateCpf } from '~/utils/validCpf';

export const schemaGeneralData = object({
  fullName: string().max(128).nonempty(),
  cpf: string().min(11).nonempty(),
  documents: array(
    object({
      acronym: string().max(32).optional(),
      country: string().max(32).optional(),
      descr: string().max(128).optional(),
      expires: string().max(32).optional(),
      documentId: number().optional(),
      issued: string().max(32).optional(),
      issuer: string().max(128).optional(),
      name: string().max(128).optional(),
      value: string().max(32).optional(),
    }),
  ),
  rg: string().max(32).min(1).nonempty(),
  birthdate: string().optional(),
  motherName: string().max(256).optional(),
  gender: a(['MALE', 'FEMALE', 'OTHER', '']),
});

export const schemaContacts = object({
  phone: string().max(14).min(11).nonempty(),
  phone2: string().max(14).optional(),
  phone3: string().max(14).optional(),
  email: string().email().max(128),
});

export const schemaAddress = object({
  zipcode: string().max(10).min(8).optional(),
  uf: string().max(2).min(2).optional(),
  city: string().max(128).optional(),
  country: string().max(3).optional(),
  neighborhood: string().max(128).optional(),
  street: string().max(256).optional(),
  number: string().max(20).optional(),
  complement: string().max(1024).optional(),
});

export const schemaPerfil = object({
  fullName: string()
    .max(128)
    .nonempty({ message: 'Insira seu nome completo.' })
    .optional(),
  firstName: string().max(64).optional(),
  lastName: string().max(64).optional(),
  cpf: string()
    .max(11)
    .min(11, { message: 'CPF inválido.' })
    .refine(value => validateCpf(value), {
      message: 'CPF inválido.',
    })
    .nonempty(),
  rg: string()
    .max(32, { message: 'RG inválido, número de digitos ultrapassou o limite' })
    .min(1, { message: 'RG inválido, existe(m) digitos faltando' })
    .optional(),
  birthdate: string().optional(),
  gender: a(['MALE', 'FEMALE', 'OTHER', '']),
  motherName: string().max(256).optional(),
  phone: string().max(14).min(11, { message: 'Telefone inválido.' }).nonempty(),
  phone2: string().max(14).nullable().optional(),
  phone3: string().max(14).nullable().optional(),
  email: string().email({ message: 'Email inválido.' }).max(128),
  zipcode: string()
    .max(10, {
      message: 'CEP inválido, número de digitos ultrapassou o limite',
    })
    .min(8, { message: 'CEP inválido, existe(m) digitos faltando' })
    .optional(),
  uf: string().min(2).optional(),
  city: string().max(128).optional(),
  country: string().max(3).optional(),
  neighborhood: string().max(128).nonempty({ message: 'Insira seu bairro.' }),
  street: string().max(256).nonempty({ message: 'Insira a rua.' }),
  number: string().max(20).optional(),
  complement: string().max(1024).optional(),
});

export const schema = object({
  login: string().max(64).optional(),
  firstName: string().max(64).optional(),
  lastName: string().max(64).optional(),
  email: string().email().max(128).optional(),
  emailVerified: boolean().optional(),
  password: object({
    currentPassword: string().min(6).max(32),
    newPassword: string().min(6).max(32),
    confirmation: string().min(6).max(32),
  })
    .optional()
    .refine(pwd => pwd?.newPassword === pwd?.confirmation, {
      message: 'Password Confirmation does not match',
    }),
  phone: string().max(14).optional(),
  phone2: string().max(14).nullable().optional(),
  phone3: string().max(14).nullable().optional(),
  cpf: string().max(11).optional(),
  rg: string().max(10).optional(),
  address: object({
    zipcode: string().max(10).optional(),
    uf: string().max(2).optional(),
    city: string().max(128).optional(),
    country: string().max(3).optional(),
    neighborhood: string().max(128).optional(),
    street: string().max(256).optional(),
    number: string().max(20).optional(),
    complement: string().max(1024).optional(),
  }).optional(),
  avatar: string().max(1024).nullable().optional(),
  motherName: string().max(256).optional(),
  birthdate: string().optional(),
  gender: a(['MALE', 'FEMALE', 'OTHER', '']),
  mainRole: string().max(3).optional(),
});
