import React, {
  useState,
  InputHTMLAttributes,
  ChangeEvent,
  useEffect,
} from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { iStore } from '~/domain/interfaces/models';
import { makeRemoteCreateSupportDocs } from '~/main/factories/usecases/supportDoc/CreateSupportDocsFactory';
import {
  makeReduxUpdateUser,
  makeRemoteUpdateUser,
} from '~/main/factories/usecases/users/UpdateUserFactory';
import { IconCamera, IconErrorInput } from '~/presentation/base/icons';

import { Container } from './styles/StyledUploader';

interface ImageData extends InputHTMLAttributes<HTMLInputElement> {
  src?: string;
}

interface ownProps {
  setUpdate?(state: boolean): void;
  size?: string;
}

interface iParams {
  id: string;
}

const Uploader: React.FC<ownProps> = props => {
  const [img, setImg] = useState<ImageData | null>({ src: '' });

  const onUpload = (data: any) => {
    const formData = new FormData();
    formData.append('filename', data);
    // uploadImage(formData)
  };

  const params = useLocation<iParams>().state;
  const userLogged = useSelector((store: iStore) => store.auth.info.user);
  const selectedUser = useSelector(
    (store: iStore) => store.users.selected.user,
  );

  // const id = useSelector((store: iStore) => store.users.selected);
  const onChange = (data: any) => {
    const formData = new FormData();
    formData.append('avatar', data.target.files[0]);

    makeReduxUpdateUser().update({
      avatar: formData,
      id:
        params && params.id !== undefined
          ? Number(params.id)
          : selectedUser?.id || userLogged?.id,
    });
  };

  useEffect(() => {
    setTimeout(() => {
      if (props.setUpdate) props.setUpdate(false);
    }, 1000);
  }, [userLogged?.avatar, selectedUser?.avatar]);

  return (
    <>
      <Container size={props.size}>
        <div>
          <IconCamera />
        </div>
        <input
          type="file"
          id="photo"
          name="photo"
          accept="image/png, image/jpeg"
          onChange={onChange}
          style={{ display: 'none' }}
        />
      </Container>
      {/* <img src = {img?.src} width = "300" height = "300"/> */}
    </>
  );
};

export default Uploader;
