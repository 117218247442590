import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';

import { V4hSpin } from '~/presentation/components/spin';
import { Unauthorized } from '~/presentation/pages/errors';
import Billing from '~/presentation/roles/adm/pages/billing';

export const BillingFactory: React.FC = () => {
  const loading = useSelector((state: iStore) => state.clinicalDocs.loading);
  const { role } = useSelector((store: iStore) => store.auth.selectUser);

  // useEffect(() => {
  //   makeReduxGetAllClinicalDocs().getAll({
  //     filter: {
  //       org: orgId,
  //     },
  //   });
  // }, []);

  if (loading) return <V4hSpin fullScreen background="white" />;

  switch (role) {
    case 'ADM':
      return <Billing />;
    default:
      return <Unauthorized />;
  }
};
