import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { GetInvoiceServiceBilling as UsecaseRemoteGetInvoiceServiceBilling } from '~/domain/usecases/billing/remote';
import { GetInvoiceServiceBilling as UseCaseReduxGetInvoiceServiceBilling } from '~/domain/usecases/billing/redux';

import { RemoteGetInvoiceServiceBilling } from '~/data/repository/billing';
import { ReduxGetInvoiceServiceBilling } from '~/data/store/reducer/billing/usecases';

/**
 * send request via API.
 */
export const makeRemoteGetInvoiceServiceBilling =
  (): UsecaseRemoteGetInvoiceServiceBilling =>
    new RemoteGetInvoiceServiceBilling(
      makeApiUrl('/billing'),
      makeHttpClient(),
    );

/**
 * send request via REDUX.
 */
export const makeReduxGetInvoiceServiceBilling =
  (): UseCaseReduxGetInvoiceServiceBilling =>
    new ReduxGetInvoiceServiceBilling();
