import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { createIntl, createIntlCache } from 'react-intl';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';
import { makeRemoteCreateOrgUnit } from '~/main/factories/usecases/orgUnit/CreateOrgUnitFactory';
import { History } from '~/main/routes';
import { PageHeader } from '~/presentation/components/header';
import { Button, Input, Select } from '~/presentation/components/UI';
import {
  Container,
  Body,
  Box,
  Content,
  Footer,
  Left,
  Right,
  Wrapper,
} from './styles/StyledNewOrgUnit';
import { Menu } from '~/presentation/components/menu';
import LogoDropzone, { fileProps } from '~/presentation/components/dropzone';
import { CreateOrgUnit as iCreateOrgUnit } from '~/domain/usecases/orgUnit/remote/CreateOrgUnit';
import { ConnectComponent, createOrgUn } from './mapper/NewOrgUnitMapper';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { makeRemoteUpdateOrgUnit } from '~/main/factories/usecases/orgUnit/UpdateOrgUnitFactory';
import { makeReduxGetAllOrgUnitList } from '~/main/factories/usecases/orgUnit/OrgUnitListFactory';
import { iStore } from '~/domain/interfaces/models';
import { translator } from '~/presentation/components/i18n';
import { V4hSpin } from '~/presentation/components/spin';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

export interface orgUnitProps {
  data: createOrgUn[];
}

const NewOrgUnit: React.FC<orgUnitProps> = ({ data }) => {
  const [orgUnitInfo, setOrgUnitInfo] = useState<createOrgUn[]>(data);
  const [selectedOrg, setSelectedOrg] = useState<number>(-1);
  const [orgUnitName, setOrgUnitName] = useState('');
  const [dropLogo, setDropLogo] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const { orgId } = useSelector((store: iStore) => store.auth.selectUser);

  useEffect(() => {
    if (data.length) {
      setSelectedOrg(data[0].id);
    }
  }, []);

  const leavePage = () => {
    History.getHistory().goBack();
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    setLoading(true);
    makeRemoteCreateOrgUnit()
      .create({
        org: selectedOrg,
        name: orgUnitName.replace(/\s/g, ''),
        fullname: orgUnitName,
      })
      .then(res => {
        setSelectedOrg(data[0].id);

        if (dropLogo) {
          const formData = new FormData();
          formData.append('logo', dropLogo);

          makeRemoteUpdateOrgUnit()
            .patch({ logo: formData, ouId: res.id })
            .then(() => setLoading(false))
            .catch(err => {
              setLoading(false);
              AlertMessage({
                type: 'danger',
                message: intl.formatMessage({
                  id: 'Não foi possível associar imagem ao setor.',
                }),
              });
            });
        } else {
          setLoading(false);
        }

        makeReduxGetAllOrgUnitList().list({
          filter: { org: orgId, enabled: true },
        });

        AlertMessage({
          type: 'success',
          message: intl.formatMessage({
            id: 'Setor criado com sucesso!',
          }),
        });

        setTimeout(() => {
          History.back();
        }, 1500);
      })
      .catch(error => {
        setLoading(false);
        AlertMessage({
          type: 'danger',
          message: intl.formatMessage({ id: 'Não foi possível criar setor.' }),
        });
      });
  };

  return (
    <>
      <Container>
        <Menu />
        <PageHeader
          title={translator('Criar novo setor')}
          backDescription={translator('Voltar para a listagem de setores')}
        />
        <Wrapper>
          <Body>
            <Left>
              <p>Marca do setor</p>
              <Box>
                <LogoDropzone handleFile={setDropLogo} />
              </Box>
            </Left>
            <Right>
              <Content>
                <Select
                  width="380px"
                  name="orgs"
                  value={selectedOrg}
                  label="Organização"
                  disabled
                >
                  {orgUnitInfo.map(item => (
                    <option key={item.id} value={item.id}>
                      {`${item.shortname}`}
                    </option>
                  ))}
                </Select>
                <Input
                  autoFocus
                  width="380px"
                  name="sectorName"
                  label="Nome do setor"
                  placeholder="Digite o nome do setor"
                  value={orgUnitName}
                  onChange={e => setOrgUnitName(e.target.value)}
                />
              </Content>
            </Right>
          </Body>

          <Footer onSubmit={handleSubmit}>
            <Button
              id="btn_cancel"
              color="red"
              rounded
              variant="secundary"
              onClick={() => leavePage()}
              size="medium"
            >
              Cancelar
            </Button>
            <Button
              id="btn_save"
              rounded
              size="medium"
              type="submit"
              onClick={handleSubmit}
            >
              Salvar
            </Button>
          </Footer>
        </Wrapper>
      </Container>
      {loading && <V4hSpin fullScreen />}
    </>
  );
};

export default ConnectComponent(NewOrgUnit);
