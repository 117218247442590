import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { iStore } from '~/domain/interfaces/models';

import { schemaGeneralData } from '~/validation/validators/appointment/CreateAppointmentValidator';

import { Select } from '../UI';
import { Container, Form } from './styles/StyledGeneralData';
import { translator } from '../i18n';
import {
  iListConsultant,
  iListHealthPlan,
  iListProfessional,
  iListSpeciality,
  iRegisterAppointment,
} from './interface';

import { ConnectComponent } from './mapper/consultant';

import { Navigator } from './Navigator';
import {
  makeReduxSetupProfession,
  makeRemoteGetAllProfession,
} from '~/main/factories/usecases/profession';
import { GetHealthPlan } from '~/domain/usecases/healthPlan/Remote';
import { makeRemoteGetHealthPlan } from '~/main/factories/usecases/healthPlan/GetHealthPlan';

export interface externalProps {
  next: (data: iRegisterAppointment) => any;
  state?: iRegisterAppointment;
}

export interface ownProps {
  specialty: iListSpeciality[];
  professionals: iListProfessional[];
  consultant: iListConsultant[];
  filterProfessional: (id: number | undefined) => iListProfessional[];
}

const GeneralData: React.FC<ownProps & externalProps> = ({
  next,
  state,
  specialty,
  professionals,
  consultant,
  filterProfessional,
}): JSX.Element => {
  const { errors, handleSubmit, register, setValue, getValues, watch } =
    useForm({
      mode: 'onChange',
      shouldFocusError: true,
      resolver: zodResolver(schemaGeneralData),
      defaultValues: {
        ...state,
      },
    });

  const [healthPlan, setHealthPlan] = useState<iListHealthPlan[]>(
    [] as iListHealthPlan[],
  );

  const { results } = useSelector((store: iStore) => store.professions);
  const { info } = useSelector((store: iStore) => store.auth);

  const watchSpecialty = watch('specialty');

  const role = useSelector((states: iStore) => states.auth.info.user?.mainRole);

  const onSubmit = handleSubmit(data => {
    next(data);
  });

  useEffect(() => {
    async function loadHealthPlan() {
      try {
        const response = await makeRemoteGetHealthPlan().get({});

        if (response) {
          setHealthPlan(response);
        } else {
          throw Error();
        }
      } catch (err) {
        console.error(err);
      }
    }

    loadHealthPlan();
  }, []);

  return (
    <Container>
      <Form onSubmit={onSubmit}>
        <Select
          id="appointmentPatient"
          name="consultant"
          defaultValue={state?.consultant}
          label={translator('Paciente')}
          onChange={e => setValue('consultant', Number(e.target.value))}
          register={() => register('consultant')}
          error={Boolean(errors.consultant)}
          message={
            errors?.consultant?.message
              ? translator(errors?.consultant?.message)
              : ''
          }
          autoFocus
          required
        >
          <option id="option_0" value={-1}>
            {translator('Selecione um paciente')}
          </option>
          {consultant.map((item, index) => (
            <option id={`option_${index}`} value={Number(item.id)}>
              {`${item.firstName} ${item.lastName}`}
            </option>
          ))}
        </Select>

        <Select
          id="select_appointmentType"
          name="type"
          defaultValue={state?.type}
          label={translator('Tipo de serviço')}
          onChange={e => setValue('type', e.target.value)}
          register={() => register('type')}
          error={Boolean(errors.type)}
          message={errors.type?.message && 'Selecione um tipo de atendimento'}
          required
        >
          <option id="option_0" value={-1}>
            {translator('Selecione um tipo')}
          </option>
          <option id="option_1" value="FIRST">
            {translator('Primeiro atendimento')}
          </option>
          <option id="option_3" value="NEW">
            {translator('Novo atendimento')}
          </option>
          <option id="option_2" value="RETURN">
            {translator('Retorno')}
          </option>
        </Select>

        <Select
          label={translator('Convênio')}
          id="healthPlan"
          defaultValue={getValues().healthPlan}
          onChange={e => setValue('healthPlan', Number(e.target.value))}
          register={() => register('healthPlan')}
        >
          <option value={0}>{translator('Nenhum')}</option>
          {healthPlan.map(plans => (
            <option value={Number(plans.id)}>{plans.name}</option>
          ))}
        </Select>

        <Navigator />
      </Form>
    </Container>
  );
};

export default ConnectComponent(GeneralData);
