import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  RegisterManager,
  RegisterParticipant,
  RegisterUser,
} from '~/presentation/pages';
import { makeRemoteCreateUser } from '~/main/factories/usecases/users/CreateUserFactory';
import {
  MakeRegisterManagerPage,
  MakeRegisterParticipantPage,
  MakeRegisterProfessionalPage,
} from '../user';
import { makeReduxProfessionList } from '../../usecases/profession';
import { makeReduxGetAllSpecialty } from '../../usecases/specialty/GetAllSpecialtyFactory';
import { iStore } from '~/domain/interfaces/models';
import { RegisterProfessional } from '~/presentation/components/registerProfessional';
import RegisterAdmPage from '~/presentation/pages/RegisterAdm';

export const RegisterFactory: React.FC = () => {
  const { pathname } = window.location;

  const path = pathname.split('/')[1];
  const { selected } = useSelector((state: iStore) => state.professions);

  useEffect(() => {
    makeReduxProfessionList().post({
      filter: { enabled: true, hasProfessional: true },
    });
    makeReduxGetAllSpecialty().getAll({
      pageSize: 9999,
    });
  }, [selected]);

  switch (path) {
    case 'participant':
      return <RegisterParticipant />;
    case 'professional':
      return <RegisterProfessional />;
    case 'manager':
      return <RegisterManager />;
    case 'administrator':
      return <RegisterAdmPage />;
    default:
      return <RegisterUser />;
  }
};
