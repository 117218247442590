import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Container,
  Content,
  TableBorder,
} from './styles/StyledBillingAcordeon';
import InfoBilling from './InfoBilling';
import { PropsArray, PropsObject } from './interface';
import { makeRemoteGetInvoiceServiceBilling } from '~/main/factories/usecases/billing/GetInvoiceServiceBilling';
import { iStore } from '~/domain/interfaces/models';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fonts: {
      fontSize: 12,
      color: '#1c1413',
    },
    description: {
      fontWeight: 500,
      fontSize: 12,
    },
    totalValue: {
      fontSize: 12,
      color: '#1c1413',
    },
    details: {
      height: 15,
      width: 15,
    },
    removePadding: {
      padding: 0,
      margin: 0,
    },
  }),
);

const ItemBillingAcordeon: React.FC<PropsObject> = ({
  invoiceServiceItems,
  serviceCode,
  invoiceId,
  show,
  setShow,
}) => {
  const [collapse, setCollapse] = useState(show);
  const [infoBilling, setInfoBilling] = useState<any>({});
  const classes = useStyles();

  const { orgId } = useSelector((store: iStore) => store.auth.selectUser);

  useEffect(() => {
    if (!show && collapse) {
      setCollapse(false);
    } else if (!collapse) {
      setShow?.((prevState: boolean) => {
        if (!prevState) {
          return false;
        }
        return prevState;
      });
    }
  }, [show, collapse]);

  function handleCloseCollapse() {
    if (!show) {
      if (collapse) {
        setCollapse(false);
      } else if (orgId && serviceCode && invoiceId)
        makeRemoteGetInvoiceServiceBilling()
          .getBillingInvoiceService({
            org: orgId,
            service: serviceCode,
            invoice: invoiceId,
          })
          .then(res => {
            setInfoBilling(res);
            setCollapse(true);
          })
          .catch(err => {
            console.log('Error: ', err);
          });
    } else if (show && collapse) {
      setCollapse(false);
    } else {
      setCollapse(true);
    }
  }

  return (
    <Content>
      <Accordion expanded={collapse} onChange={handleCloseCollapse}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Container>
            <Typography className={classes.description}>
              {invoiceServiceItems.service.descr}
            </Typography>
            <Typography align="right" className={classes.fonts}>
              {invoiceServiceItems.usage.limit ?? '-'}
            </Typography>
            <Typography align="right" className={classes.fonts}>
              {invoiceServiceItems.usage.used.toFixed(3)}
            </Typography>
            <Typography align="right" className={classes.fonts}>
              {invoiceServiceItems.usage.unit}
            </Typography>
            <Typography align="right" className={classes.fonts}>
              {new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: invoiceServiceItems.price.currency ?? 'BRL',
              }).format(invoiceServiceItems.price.priceUnit)}
            </Typography>
            <Typography align="right" className={classes.totalValue}>
              {new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: invoiceServiceItems.price.currency ?? 'BRL',
              }).format(invoiceServiceItems.price.priceTotal)}
            </Typography>
          </Container>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{ width: '100%' }}>
            <InfoBilling items={infoBilling.items} />
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Content>
  );
};

export default ItemBillingAcordeon;
