/* eslint-disable import/no-duplicates */
import React, { useEffect, useState } from 'react';
import { ChakraProvider } from '@chakra-ui/react';

import { ThemeProvider } from 'styled-components';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ptBR from 'date-fns/locale/pt';
import enUS from 'date-fns/locale/en-CA';
import es from 'date-fns/locale/es';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import ReactNotification from 'react-notifications-component';
import { IntlProvider } from 'react-intl';

import Notifications from '~/presentation/components/messages';
import AccessControl from '~/presentation/hooks/access';
import { Routes } from '~/main/routes';
import store from '~/data/store';
import GlobalStyle from './presentation/base/styles/globals';
import { defaultTheme } from './presentation/base/themes';
import translations from './infra/i18n/locales';

import { ConnectSocket } from '~/infra/socket/ConnectSocket';
import 'react-notifications-component/dist/theme.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css/animate.compat.css';

import './infra/global/variables';

const persistor = persistStore(store);

export interface iSupportedLanguages {
  language:
    | 'pt-BR'
    | 'pt-br'
    | 'en-US'
    | 'en-us'
    | 'es'
    | 'es-ES'
    | 'es-es'
    | 'es-DO'
    | 'es-do'
    | 'es-GT'
    | 'es-gt'
    | 'es-HN'
    | 'es-hn'
    | 'es-MX'
    | 'es-mx'
    | 'es-PA'
    | 'es-pa'
    | 'es-PE'
    | 'es-pe'
    | 'es-SV'
    | 'es-SE'
    | 'es-sv'
    | 'es-se';
}

function App(): JSX.Element {
  const [selectedLocale, setSelectedLocale] =
    useState<iSupportedLanguages['language']>('en-US');
  const [dateLocale, setDateLocale] = useState(ptBR);

  useEffect(() => {
    let userLanguage = localStorage.getItem('i18nextLng');

    if (!userLanguage) {
      userLanguage = navigator.language;
      localStorage.setItem('i18nextLng', userLanguage);
    }

    setSelectedLocale(userLanguage as iSupportedLanguages['language']);
    if (userLanguage.includes('pt')) setDateLocale(ptBR);
    if (userLanguage.includes('en')) setDateLocale(enUS);
    if (userLanguage.includes('es')) setDateLocale(es);
  }, []);

  return (
    <IntlProvider
      messages={translations[selectedLocale]}
      locale={String(selectedLocale)}
      defaultLocale="pt-BR"
    >
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <AccessControl>
            <ConnectSocket>
              <Notifications>
                <ThemeProvider theme={defaultTheme}>
                  <ChakraProvider>
                    <MuiPickersUtilsProvider
                      utils={DateFnsUtils}
                      locale={dateLocale}
                    >
                      <GlobalStyle />
                      <ReactNotification />
                      <Routes />
                    </MuiPickersUtilsProvider>
                  </ChakraProvider>
                </ThemeProvider>
              </Notifications>
            </ConnectSocket>
          </AccessControl>
        </PersistGate>
      </Provider>
    </IntlProvider>
  );
}

export default App;
