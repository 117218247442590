import styled from 'styled-components';

export const Container = styled.form`
  /* width: 100%;
  height: fit-content;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;

  #btnAction {
    max-width: 200px;
    height: fit-content;
    padding: 10px;
    width: 100%;
  } */

  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  justify-content: space-between;
`;

export const Header = styled.div`
  width: 100%;
`;

export const Body = styled.div`
  width: 100%;
  /* margin-top: 50px; */
  margin-bottom: 100px;
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  .btnAction {
    width: 150px;
  }
`;

export const Card = styled.div`
  width: 100%;
  border-radius: 5px;
  justify-self: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f4f7fe;
  padding: 20px;

  #item1 {
    grid-area: item1;
    Select {
      border-radius: 5px;
      background: #ffffff;
    }
  }

  #item2 {
    grid-area: item2;
    Input {
      border-radius: 5px;
      background: #ffffff;
    }
  }

  #item3 {
    grid-area: item3;
    Input {
      border-radius: 5px;
      background: #ffffff;
    }
  }

  #item4 {
    grid-area: item4;
    Input {
      border-radius: 5px;
      background: #ffffff;
    }
  }

  .action {
    grid-area: action;
    display: flex;
    justify-content: flex-end;
  }
`;

export const EmptySpecialty = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #c4c4c4;
  border-radius: 15px;
  padding: 20px;
`;

export const NoEmptySpecialty = styled.div`
  width: 100%;
  min-height: 242px;
  max-height: 242px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border: 1px solid #c4c4c4;
  overflow-y: scroll;
`;
