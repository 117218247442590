import store from '~/data/store';
import { iStore } from '~/domain/interfaces/models';
import { makeRemoteGetAppointmentById } from '~/main/factories/usecases/appointment/GetAppointmentbyIdFactory ';
import {
  iListProfessional,
  AppointmentDetail,
  iSession,
  iUser,
  iGuest,
} from '../interface';

export const filterProfessional = (
  id: number | undefined,
): iListProfessional[] => {
  const { professional }: iStore = store.getState();

  const arr: iListProfessional[] = professional.results.filter(prof => {
    return prof.specialties.filter(specialty => specialty.id === id);
  });

  return arr;
};

export const filterAppointment = async (
  appointmentID: number,
): Promise<AppointmentDetail> => {
  const detail = await makeRemoteGetAppointmentById().getAppointmentById({
    id: appointmentID,
  });

  console.log('###Detail', detail);

  const session: iSession = {
    status: detail.appointment.status,
    title: `${
      detail.appointment.type === 'FIRST' ? 'Atendimento' : 'Retorno'
    } - ${detail.specialty.name}`,
    date: detail.appointment.scheduled,
    mounth: 'FEV',
    timeInterval: '08:00 - 12:00',
  };

  const user: iUser = {
    id: detail.consultant?.id || 0,
    name: `${detail.professional?.firstName} ${detail.professional?.lastName}`,
    recordAdvice: `${detail.professional?.docProf} - ${detail.professional?.docProfUF}`,
    specialty: detail.specialty.name,
  };

  const guest: iGuest = {
    email: String(detail.companions[0]?.email) || '',
    name: detail.companions[0]?.name || '',
  };

  return { user, session, guest };
};
