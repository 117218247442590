import React, { ComponentType } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { iStore, iInvoiceResults } from '~/domain/interfaces/models';

import { ownProps, DashboardSelectOptions } from '../interface';

/**
 * Connect component.
 * @param Component componente to connect.
 */
export function ConnectComponent<P>(
  Component: ComponentType<P & ownProps>,
): React.FC<P> {
  const Render: React.FC<P> = ({ ...rest }) => {
    const { invoiceItems } = useSelector((store: iStore) => store.billing);

    Component.defaultProps = {
      dashboardMonths: MapDashboardMonths(invoiceItems),
      ...(rest as P),
    };

    return <Component {...(rest as P & ownProps)} />;
  };

  return Render;
}

/**
 * Map list dashboard months to component.
 * @param dashboardMonths list of dashboardMonths.
 */
export const MapDashboardMonths = (
  dashboard: iInvoiceResults,
): DashboardSelectOptions[] => {
  const arr: DashboardSelectOptions[] = [];

  dashboard?.items?.forEach(item => {
    const formattedDate = new Date(`${item.invoice.begin}T00:00`);
    const month = formattedDate.toLocaleString('pt-BR', {
      month: 'long',
    });
    const formattedMonth = month.replace(
      month[0],
      month.charAt(0).toUpperCase(),
    );

    const label = `${formattedMonth} ${formattedDate.getFullYear()}`;

    arr.push({
      label,
      value: {
        month: formattedDate.getMonth() + 1,
        year: formattedDate.getFullYear(),
      },
    });
  });

  const orderedArray = _.orderBy(
    arr,
    item => item.value.month + item.value.year,
    ['desc'],
  );

  return orderedArray;
};
