/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-no-comment-textnodes */
// eslint-disable-next-line react/jsx-one-expression-per-line
import React, { useState } from 'react';
import { Menu, Services } from '~/presentation/components/menu';
import { HeaderSimples } from '~/presentation/components/header';
import {
  Container,
  Body,
  ContentLeft,
  ContentRight,
  ContainerRight,
} from './styles/StyledBilling';

import { InvoiceIconLighter } from '~/presentation/base/icons';

import { InvoiceAndPayments } from '~/presentation/components/invoiceAndPayments';
import { ServicesAdmin } from '~/presentation/components/ServicesAdmin';
import { translator } from '~/presentation/components/i18n';

const Billing: React.FC = () => {
  const [activeMenu, setActiveMenu] = useState<number>(0);

  const menuOptions = [
    `${translator('Serviços')}`,
    `${translator('Faturas e pagamentos')}`,
  ];

  return (
    <Container>
      <Menu />
      <HeaderSimples
        title={translator('Serviços e faturamentos')}
        icon={InvoiceIconLighter}
      />
      <Body>
        <ContentLeft>
          <Services
            setState={setActiveMenu}
            options={menuOptions}
            active={activeMenu}
          />
        </ContentLeft>
        {activeMenu === 0 && (
          <ContainerRight>
            <ServicesAdmin />
          </ContainerRight>
        )}
        {activeMenu === 1 && (
          <ContentRight>
            <InvoiceAndPayments />
          </ContentRight>
        )}
      </Body>
    </Container>
  );
};

export default Billing;
