import React, { useEffect, useState } from 'react';

import { HeaderSimples } from '~/presentation/components/header';
import { SearchListOrganizer } from '~/presentation/components/search-list';
import { Button } from '~/presentation/components/UI';
import { Menu } from '~/presentation/components/menu';
import {
  iListRegisteredManager,
  ListRegisteredManager,
} from '../../../../components/ListManager';

import {
  Container,
  Body,
  HeaderList,
  Wrapper,
  Attendiment,
} from './StyledSchedule';
import { History } from '~/main/routes';
import { ConnectManager } from '~/presentation/components/ListManager/mapper/MapperListManager';
import { TablePagination } from '~/presentation/components/tablePagination';
import { IconPlus } from '~/presentation/base/icons';
import { translator } from '~/presentation/components/i18n';
import { FilterOrganizer } from '~/presentation/components/filter';
import { makeReduxDataPagination } from '~/main/factories/usecases/dataPagination/SetDataFactory';

export interface pageProps {
  data: iListRegisteredManager[];
}
const ListManager: React.FC<pageProps> = ({ data }): JSX.Element => {
  const url = window.location.pathname;
  const [state, setState] = useState(data);

  useEffect(() => {
    if (data.length === 0) {
      makeReduxDataPagination().set([]);
    }

    setState(data);
  }, [data]);

  return (
    <Container>
      <Menu />
      <HeaderSimples
        title={translator('Organizadores cadastrados')}
        value={state.length}
        valueDescription={translator('Cadastrados')}
      />

      <Body>
        <HeaderList>
          {state.length > 0 && state !== undefined ? (
            <TablePagination data={state} itemPerPage={10} />
          ) : (
            <div />
          )}
          <Wrapper>
            <div>
              <SearchListOrganizer>
                <FilterOrganizer />
              </SearchListOrganizer>
            </div>
            <Button
              id="button_create"
              onClick={() =>
                History.getHistory().push('/manager/register', {
                  from: url.replaceAll('/', ''),
                })
              }
              size="large"
              resourcers={['test']}
              rounded
            >
              <Attendiment>
                <IconPlus />
              </Attendiment>
              <text>{translator('Novo organizador')}</text>
            </Button>
          </Wrapper>
        </HeaderList>
        <ListRegisteredManager />
      </Body>
    </Container>
  );
};

export default ConnectManager(ListManager);
